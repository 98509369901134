<template>
    <div class="one-help-area two-help-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-7 p-0">
                    <div class="help-item">
                        <!-- Selector de idioma -->
                        <div class="language-switch">
                            <button @click="setLanguage('es')" :class="{ active: i18n.language === 'es' }">
                                <span class="fi fi-es"></span> ES
                            </button>
                            <button @click="setLanguage('en')" :class="{ active: i18n.language === 'en' }">
                                <span class="fi fi-gb"></span> EN
                            </button>
                            <button @click="setLanguage('nl')" :class="{ active: i18n.language === 'nl' }">
                                <span class="fi fi-nl"></span> NL
                            </button>
                        </div>

                        <div class="one-section-title">
                            <span class="sub-title">{{ i18n.translations[i18n.language].subtitle }}</span>
                            <h2>{{ i18n.translations[i18n.language].title }}</h2>
                            <p>{{ i18n.translations[i18n.language].description }}</p>
                        </div>
                        <ul>
                            <li>
                                <div class="help-icon">
                                    <i class='bx bx-car'></i>
                                </div>
                                <div class="help-inner">
                                    <h3>{{ i18n.translations[i18n.language].airportTransfer.title }}</h3>
                                    <p>{{ i18n.translations[i18n.language].airportTransfer.description }}</p>
                                </div>
                            </li>
                            <li>
                                <div class="help-icon">
                                    <i class='bx bx-star'></i>
                                </div>
                                <div class="help-inner">
                                    <h3>{{ i18n.translations[i18n.language].premiumService.title }}</h3>
                                    <p>{{ i18n.translations[i18n.language].premiumService.description }}</p>
                                </div>
                            </li>
                        </ul>
                        <div class="advisor-link">
                            <!-- Botón Solicitar Ahora para abrir el modal -->

                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 p-0">
                    <div class="help-img-two">
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- Componente RequestTrip -->
        <RequestTrip ref="requestTripRef" />
    </div>
</template>

<script>
import { i18n } from '@/i18n';


export default {
    name: 'FunFact',
    components: {

    },
    setup() {
        const setLanguage = (lang) => {
            i18n.setLanguage(lang);
        };

        return {
            i18n,
            setLanguage,
        };
    },

};
</script>

<style scoped>
.one-help-area {
    background-color: #333;
    color: #fff;
}

.language-switch {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.language-switch button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    transition: color 0.3s;
}

.language-switch button.active {
    font-weight: bold;
    color: #007bff;
}

.request-now-button {
    font-size: 1rem;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.request-now-button:hover {
    background-color: #0056b3;
}
</style>

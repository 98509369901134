<template>
  <div 
      :class="['modal fade', { show: showTripRequestModal }]" 
      tabindex="-1" 
      role="dialog" 
      :style="{ display: showTripRequestModal ? 'block' : 'none' }" 
      aria-modal="true"
  >
    <div role="document">
      <div class="modal-content custom-modal-content">
        <div class="modal-body">
          <div class="container-fluid position-relative">
            <!-- Inputs de Ubicación -->
            <div class="row g-3">
              <!-- Ubicación de Recogida -->
              <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                  <label for="pickupLocation" class="form-label">
                    <box-icon name='map'></box-icon> Ubicación de Recogida
                  </label>
                  <div class="input-icon-wrapper">
                    <button @click="setCurrentLocation" class="icon-button">
                      <box-icon type="solid" name="map-pin" color="#0FC938"></box-icon>
                    </button>
                    <input type="text" class="form-control" id="pickupLocation" v-model="pickupLocation" placeholder="Ingrese la ubicación de recogida" />
                  </div>
                </div>
              </div>
              <!-- Destino -->
              <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                  <label for="destinationLocation" class="form-label">
                    <box-icon name='map'></box-icon> Destino
                  </label>
                  <input type="text" class="form-control" id="destinationLocation" v-model="destinationLocation" placeholder="Ingrese el destino" />
                </div>
              </div>
            </div>
            <!-- Botones Calcular Ruta y Reiniciar Búsqueda -->
            <div class="row">
              <div class="col-12 text-center">
                <button class="btn btn-primary mt-2" @click="calculateRoute">Calcular Ruta</button>
                <button class="btn btn-secondary mt-2 ms-2" @click="resetSearch">Reiniciar Búsqueda</button>
              </div>
            </div>
            <!-- Fila Principal con Mapa y Elementos -->
            <div class="row mt-3">
              <!-- Columna del Mapa (60%) -->
              <div class="col-lg-7 mb-3">
                <div ref="routeMap" class="map-container" :style="{ height: mapHeight }"></div>
              </div>
              <!-- Columna de Elementos (40%) -->
              <div class="col-lg-5">
                <!-- Tipo de Vehículo -->
                <div class="mb-3">
                  <label class="form-label">Tipo de Vehículo</label>
                  <div class="seat-options d-flex">
                    <button @click="setSelectedSeats('SAVE')" :class="['btn btn-outline-primary', { active: selectedSeats === 'SAVE' }]">
                      <box-icon type='solid' name='leaf'></box-icon> 5 Save
                    </button>
                    <button @click="setSelectedSeats('PREMIUM')" :class="['btn btn-outline-primary', { active: selectedSeats === 'PREMIUM' }]">
                      <box-icon name='car'></box-icon> 5 Premium
                    </button>
                    <button @click="setSelectedSeats('VAN')" :class="['btn btn-outline-primary', { active: selectedSeats === 'VAN' }]">
                      <box-icon name='bus'></box-icon> 8 VAN
                    </button>
                  </div>
                </div>
                <!-- Tipo de Viaje -->
                <div class="mb-3">
                  <label class="form-label">Tipo de Viaje</label>
                  <div class="d-flex align-items-center">
                    <div class="form-check me-3">
                      <input class="form-check-input" type="radio" name="tripType" id="now" value="now" v-model="tripType" @change="setTripType('now')" />
                      <label class="form-check-label" for="now">Ahora</label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="tripType" id="scheduled" value="scheduled" v-model="tripType" @change="setTripType('scheduled')" />
                      <label class="form-check-label" for="scheduled">Programar</label>
                    </div>
                  </div>
                  <div v-if="tripType === 'scheduled'" class="mt-2">
                    <label for="dateTime" class="form-label">Fecha y Hora de Reserva</label>
                    <input type="datetime-local" id="dateTime" v-model="dateTime" class="form-control small-datetime-input" />
                  </div>
                </div>
                <!-- Método de Pago -->
                <div class="mb-3">
                  <label class="form-label">Método de Pago</label>
                  <div class="payment-method d-flex gap-2">
                    <button @click="setPaymentMethod('Cash')" :class="['payment-method-button', { active: paymentMethod === 'Cash' }]">
                      <box-icon name='money'></box-icon> Efectivo
                    </button>
                    <button @click="setPaymentMethod('Card')" :class="['payment-method-button', { active: paymentMethod === 'Card' }]">
                      <box-icon name='credit-card' type='solid'></box-icon> Tarjeta
                    </button>
                  </div>
                </div>
                <!-- Panel de Precios -->
                <div class="mb-3 price-details">
                  <div class="total-price mb-2">
                    <h5>Precio Estimado:</h5>
                    <p><strong>Total:</strong><span> €{{ estimatedPrice.toFixed(2) }}</span></p>
                  </div>
                  <div class="price-info d-flex flex-wrap">
                    <div class="price-item flex-fill">
                      <p><i class="bx bx-car"></i><strong> Distancia:</strong> {{ distance.toFixed(2) }} km</p>
                    </div>
                    <div class="price-item flex-fill">
                      <p><i class="bx bx-time"></i><strong> Tiempo de Espera:</strong> {{ waitingTime }} min</p>
                    </div>
                    <div class="price-item flex-fill">
                      <p><i class="bx bx-money"></i><strong> Coste por Distancia:</strong> €{{ distanceCost.toFixed(2) }}</p>
                    </div>
                    <div class="price-item flex-fill">
                      <p><i class="bx bx-time-five"></i><strong> Coste por Tiempo de Espera:</strong> €{{ waitTimeCost.toFixed(2) }}</p>
                    </div>
                  </div>
                </div>
                <!-- Botón de Solicitar Viaje -->
                <div class="text-center">
                  <button type="button" class="btn custom-btn-request" @click="requestTrip">Solicitar Viaje</button>
                </div>
              </div>
            </div>
            <!-- Fin de la Fila Principal -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Importaciones de librerías de íconos y otras dependencias
import '@mdi/font/css/materialdesignicons.css'
import 'boxicons/css/boxicons.min.css'
import axios from 'axios'
import maplibregl from 'maplibre-gl'

export default {
  name: 'RequestTrip',
  data() {
    return {
      showTripRequestModal: true,
      isFullscreen: false,
      pickupLocation: '',
      destinationLocation: '',
      passengerPhone: '+34600000000', // Teléfono por defecto
      map: null,
      distance: 0,
      estimatedPrice: 0,
      distanceCost: 0,
      waitTimeCost: 0,
      waitingTime: 0,
      selectedSeats: 'SAVE',
      paymentMethod: 'Cash',
      tripType: 'now',
      dateTime: null,
      selectedLanguage: 'es',
      latitudePickup: null,
      longitudePickup: null,
      latitudeDestination: null,
      longitudeDestination: null,
      routeLayer: null,
      modalWidth: '80%',
      modalHeight: '80%',
      mapHeight: '500px',
      pickupMarker: null,
      destinationMarker: null,
      routeDuration: null, // Duración estimada de la ruta
      tripId: null, // Para guardar el ID del viaje
      // Agrega tu token de acceso de Mapbox aquí
      mapboxAccessToken: 'pk.eyJ1Ijoic2FtaWdvcmFiIiwiYSI6ImNtMnNnbGhzdTFjZzEya3NibHd1ZzB0ZXQifQ.g7lY1cJ9CUqUR4uCC_fvBA', // Reemplaza con tu token
    };
  },
  methods: {
    initMap() {
      if (this.map) return;
      this.map = new maplibregl.Map({
        container: this.$refs.routeMap,
        style: 'https://tiles.stadiamaps.com/styles/outdoors.json',
        center: [-4.4991, 36.6213],
        zoom: 4,
      });
      this.map.addControl(new maplibregl.NavigationControl());
    },
    async calculateRoute() {
      if (!this.pickupLocation || !this.destinationLocation) {
        this.showMessage('Por favor, ingrese ambas ubicaciones.');
        return;
      }
      try {
        await Promise.all([
          this.fetchCoordinates('pickup'),
          this.fetchCoordinates('destination'),
        ]);
        if (
          this.latitudePickup &&
          this.longitudePickup &&
          this.latitudeDestination &&
          this.longitudeDestination
        ) {
          this.addMarker(
            [this.longitudePickup, this.latitudePickup],
            'green',
            'pickup'
          );
          this.addMarker(
            [this.longitudeDestination, this.latitudeDestination],
            'red',
            'destination'
          );
          this.adjustMapBounds();
          await this.getRoute();

          // Guardar datos en localStorage
          this.saveDataToLocalStorage();

          // Deshabilitar interacción con el mapa
          this.disableMapInteraction();
        } else {
          this.showMessage(
            'No se pudieron obtener las coordenadas de las ubicaciones.'
          );
        }
      } catch (error) {
        console.error('Error al calcular la ruta:', error);
        this.showMessage('Ocurrió un error al calcular la ruta.');
      }
    },
    async fetchCoordinates(type) {
      const location =
        type === 'pickup' ? this.pickupLocation : this.destinationLocation;
      try {
        const response = await axios.get(
          'https://nominatim.openstreetmap.org/search',
          {
            params: {
              q: location,
              format: 'json',
              limit: 1,
            },
          }
        );
        if (response.data && response.data.length > 0) {
          const result = response.data[0];
          const lat = parseFloat(result.lat);
          const lon = parseFloat(result.lon);
          if (type === 'pickup') {
            this.latitudePickup = lat;
            this.longitudePickup = lon;
          } else {
            this.latitudeDestination = lat;
            this.longitudeDestination = lon;
          }
        } else {
          this.showMessage(`No se encontró la ubicación: ${location}`);
        }
      } catch (error) {
        console.error(
          `Error en la búsqueda de la ubicación (${location}):`,
          error
        );
        this.showMessage(`Error en la búsqueda de la ubicación: ${location}`);
      }
    },
    addMarker(coords, color, type) {
      if (type === 'pickup' && this.pickupMarker) this.pickupMarker.remove();
      if (type === 'destination' && this.destinationMarker)
        this.destinationMarker.remove();
      const marker = new maplibregl.Marker({ color })
        .setLngLat(coords)
        .addTo(this.map);
      if (type === 'pickup') this.pickupMarker = marker;
      else if (type === 'destination') this.destinationMarker = marker;
    },
    adjustMapBounds() {
      const bounds = new maplibregl.LngLatBounds();
      if (this.longitudePickup && this.latitudePickup)
        bounds.extend([this.longitudePickup, this.latitudePickup]);
      if (this.longitudeDestination && this.latitudeDestination)
        bounds.extend([this.longitudeDestination, this.latitudeDestination]);
      if (!bounds.isEmpty()) this.map.fitBounds(bounds, { padding: 50 });
    },
    async getRoute() {
      try {
        const mapboxRoute = await this.getRouteMapbox();
        if (mapboxRoute) {
          this.processRoute(mapboxRoute.geometry.coordinates, mapboxRoute.distance);
          return;
        }
      } catch (error) {
        console.warn('Mapbox Directions API falló, intentando con OSRM.', error);
      }

      try {
        const osrmRoute = await this.getRouteOSRM();
        if (osrmRoute) {
          this.processRoute(osrmRoute.geometry.coordinates, osrmRoute.distance);
          return;
        }
      } catch (error) {
        console.error('Ambos servicios de rutas fallaron:', error);
        this.showMessage(
          'No se pudo calcular la ruta con los servicios disponibles.'
        );
      }
    },
    async getRouteMapbox() {
      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${this.longitudePickup},${this.latitudePickup};${this.longitudeDestination},${this.latitudeDestination}`;
      try {
        const response = await axios.get(url, {
          params: {
            alternatives: false,
            geometries: 'geojson',
            steps: false,
            access_token: this.mapboxAccessToken,
          },
        });
        if (
          response.data &&
          response.data.routes &&
          response.data.routes.length > 0
        ) {
          const route = response.data.routes[0];
          this.distance = route.distance / 1000; // en kilómetros
          this.routeDuration = route.duration; // duración en segundos

          // Calcular el precio
          this.recalculatePrice();

          return {
            geometry: route.geometry,
            distance: route.distance,
            duration: route.duration,
          };
        } else {
          this.showMessage('Mapbox: No se pudo calcular la ruta.');
          return null;
        }
      } catch (error) {
        console.error('Error al obtener la ruta de Mapbox:', error);
        this.showMessage('Error al obtener la ruta de Mapbox.');
        throw error; // Propagar el error para intentar con OSRM
      }
    },
    async getRouteOSRM() {
      try {
        const response = await axios.get(
          `https://router.project-osrm.org/route/v1/driving/${this.longitudePickup},${this.latitudePickup};${this.longitudeDestination},${this.latitudeDestination}`,
          {
            params: {
              overview: 'full',
              geometries: 'geojson',
            },
          }
        );
        if (
          response.data &&
          response.data.routes &&
          response.data.routes.length > 0
        ) {
          const route = response.data.routes[0];
          this.distance = route.distance / 1000; // en kilómetros
          this.routeDuration = route.duration; // duración en segundos

          // Calcular el precio
          this.recalculatePrice();

          return {
            geometry: route.geometry,
            distance: route.distance,
            duration: route.duration,
          };
        } else {
          this.showMessage('OSRM: No se pudo calcular la ruta.');
          return null;
        }
      } catch (error) {
        console.error('Error al obtener la ruta de OSRM:', error);
        this.showMessage('Error al obtener la ruta de OSRM.');
        throw error;
      }
    },
    processRoute(coordinates, distance) {
      const routeGeoJSON = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: coordinates,
        },
      };
      if (this.map.getSource('route')) {
        this.map.removeLayer('route');
        this.map.removeSource('route');
      }
      this.map.addSource('route', {
        type: 'geojson',
        data: routeGeoJSON,
      });
      this.map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#0B9A2B',
          'line-width': 7,
        },
      });
    },
    calculateRidePrice(paymentType, serviceType, distance, isImmediate) {
      // Parámetros base
      const basePriceSave = 7.75; // Precio base para el servicio SAVE
      const costPerKm = 1.38; // Coste por kilómetro
      const costPerMinuteWait = 0.52; // Coste por minuto de espera

      // Multiplicadores de servicio
      const serviceMultipliers = {
        SAVE: 1, // Multiplicador base para SAVE
        PREMIUM: 1.25, // Incremento del 25% para PREMIUM
        VAN: 1.35, // Incremento del 35% para VAN
      };

      // Determinar el multiplicador de servicio
      const serviceMultiplier = serviceMultipliers[serviceType.toUpperCase()];
      if (!serviceMultiplier) {
        throw new Error(
          'Tipo de servicio inválido. Elige entre SAVE, PREMIUM o VAN.'
        );
      }

      // Calcular tiempo de espera basado en la distancia
      let waitTimeMinutes;
      if (distance <= 5) {
        waitTimeMinutes = 2; // Distancia corta
      } else if (distance <= 15) {
        waitTimeMinutes = 5; // Distancia media
      } else {
        waitTimeMinutes = 10; // Distancia larga
      }

      // Calcular precio base del viaje
      const baseRidePrice = basePriceSave * serviceMultiplier;

      // Calcular coste por distancia
      let distanceCost = distance * costPerKm;

      // Calcular coste por tiempo de espera
      let waitTimeCost = waitTimeMinutes * costPerMinuteWait;

      // Ajustar por tipo de pago (10% más para efectivo)
      if (paymentType.toLowerCase() === 'efectivo') {
        distanceCost *= 1.1;
        waitTimeCost *= 1.1;
      }

      // Ajustar por inmediatez (10% más para viajes inmediatos)
      if (isImmediate) {
        distanceCost *= 1.1;
        waitTimeCost *= 1.1;
      }

      // Coste total del viaje
      let totalCost = baseRidePrice + distanceCost + waitTimeCost;

      // Formatear a dos decimales
      totalCost = totalCost.toFixed(2);
      distanceCost = distanceCost.toFixed(2);
      waitTimeCost = waitTimeCost.toFixed(2);

      return {
        serviceType,
        paymentType,
        distance,
        isImmediate,
        waitTimeMinutes,
        totalCost: parseFloat(totalCost),
        distanceCost: parseFloat(distanceCost),
        waitTimeCost: parseFloat(waitTimeCost),
      };
    },
    setCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.map.flyTo({
              center: [longitude, latitude],
              zoom: 14,
            });
            if (this.pickupMarker) {
              this.pickupMarker.setLngLat([longitude, latitude]);
            } else {
              this.pickupMarker = new maplibregl.Marker({ color: 'blue' })
                .setLngLat([longitude, latitude])
                .addTo(this.map);
            }
            this.pickupLocation = `${latitude.toFixed(5)}, ${longitude.toFixed(
              5
            )}`;
          },
          (error) => {
            console.error('Error al obtener la ubicación:', error);
            this.showMessage('No se pudo obtener la ubicación.');
          }
        );
      } else {
        this.showMessage(
          'La geolocalización no es compatible con este navegador.'
        );
      }
    },
    setPaymentMethod(method) {
      this.paymentMethod = method;
      if (this.distance > 0) {
        this.recalculatePrice();
        localStorage.setItem('MetodoDePago', this.paymentMethod);
        localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
      }
    },
    setSelectedSeats(seats) {
      this.selectedSeats = seats;
      if (this.distance > 0) {
        this.recalculatePrice();
        localStorage.setItem('VehiculoSeleccionado', this.selectedSeats);
        localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
      }
    },
    setTripType(type) {
      this.tripType = type;
      if (this.distance > 0) {
        this.recalculatePrice();
        localStorage.setItem('TipoDeViaje', this.tripType);
        localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
      }
    },
    recalculatePrice() {
      const paymentType = this.paymentMethod;
      const serviceType = this.selectedSeats;
      const isImmediate = this.tripType === 'now';

      const paymentTypeFormatted =
        paymentType.toLowerCase() === 'cash' ? 'efectivo' : 'tarjeta';

      const result = this.calculateRidePrice(
        paymentTypeFormatted,
        serviceType,
        this.distance,
        isImmediate
      );

      this.estimatedPrice = result.totalCost;
      this.distanceCost = result.distanceCost;
      this.waitTimeCost = result.waitTimeCost;
      this.waitingTime = result.waitTimeMinutes;
    },
    disableMapInteraction() {
      this.map.scrollZoom.disable();
      this.map.dragPan.disable();
      this.map.dragRotate.disable();
      this.map.keyboard.disable();
      this.map.doubleClickZoom.disable();
      this.map.touchZoomRotate.disable();
    },
    saveDataToLocalStorage() {
      localStorage.setItem('Origen', this.pickupLocation);
      localStorage.setItem('Destino', this.destinationLocation);
      localStorage.setItem('FechaYHora', this.dateTime);
      localStorage.setItem('TipoDeViaje', this.tripType);
      localStorage.setItem('VehiculoSeleccionado', this.selectedSeats);
      localStorage.setItem('MetodoDePago', this.paymentMethod);
      localStorage.setItem('LatitudOrigen', this.latitudePickup.toString());
      localStorage.setItem('LongitudOrigen', this.longitudePickup.toString());
      localStorage.setItem('LatitudDestino', this.latitudeDestination.toString());
      localStorage.setItem(
        'LongitudDestino',
        this.longitudeDestination.toString()
      );
      localStorage.setItem('PrecioEstimado', this.estimatedPrice.toString());
    },
    setLanguage(lang) {
      this.selectedLanguage = lang;
    },
    async requestTrip() {
      // Validar entradas
      if (!this.pickupLocation || !this.destinationLocation) {
        this.showMessage('Por favor, completa las ubicaciones.');
        return;
      }

      // Estimar dropoff_datetime basado en la duración del viaje
      let dropoffDatetime = null;
      if (this.routeDuration) {
        const estimatedArrivalTime = new Date();
        estimatedArrivalTime.setSeconds(
          estimatedArrivalTime.getSeconds() + this.routeDuration
        );
        dropoffDatetime = estimatedArrivalTime.toISOString();
      }

      // Datos del viaje a enviar
      const tripData = {
        passenger_phone: '+34600000000', // Teléfono por defecto
        pickup_location: this.pickupLocation,
        dropoff_location: this.destinationLocation,
        vehicle_type: this.selectedSeats,
        requested_at: new Date().toISOString(),
        is_scheduled: this.tripType === 'scheduled',
        pickup_datetime:
          this.tripType === 'scheduled' && this.dateTime
            ? new Date(this.dateTime).toISOString()
            : new Date().toISOString(), // Asignar la hora actual si es inmediato
        dropoff_datetime: dropoffDatetime, // Hora estimada de llegada
        distance_km: this.distance.toFixed(2),
        estimated_price: this.estimatedPrice.toFixed(2),
        total_price: this.estimatedPrice.toFixed(2),
        user: null, // Usuario anónimo
      };

      // Mostrar tripData en la consola para verificar la estructura
      console.log('Datos enviados:', tripData);

      try {
        // Enviar datos a la API
        const response = await axios.post(
          'http://127.0.0.1:8000/api/trips/',
          tripData
        );

        if (response.status === 201) {
          // Guardar el ID del viaje en localStorage
          this.tripId = response.data.id;
          localStorage.setItem('TripId', this.tripId.toString());

          this.showMessage(
            'Viaje solicitado con éxito. Redirigiendo a pagos...'
          );
          // Redirigir a la sección de pagos
          this.$router.push('/pagos');
        } else {
          console.log('Respuesta inesperada:', response);
          this.showMessage(
            'Hubo un problema al solicitar el viaje. Inténtalo de nuevo.'
          );
        }
      } catch (error) {
        if (error.response) {
          console.error('Error al solicitar el viaje:', error.response.data);
          console.error('Estado:', error.response.status);
          // Mostrar mensajes de error específicos si están disponibles
          if (error.response.data) {
            const errorMessages = Object.values(error.response.data)
              .flat()
              .join(' ');
            this.showMessage(`Error: ${errorMessages}`);
          } else {
            this.showMessage(
              'Error al conectar con la API. Por favor, verifica tu conexión o inténtalo más tarde.'
            );
          }
        } else {
          console.error('Error al solicitar el viaje:', error.message);
          this.showMessage(
            'Error al conectar con la API. Por favor, verifica tu conexión o inténtalo más tarde.'
          );
        }
      }
    },
    resetSearch() {
      this.pickupLocation = '';
      this.destinationLocation = '';
      this.passengerPhone = '+34600000000'; // Reinicia el teléfono al valor por defecto
      this.latitudePickup = null;
      this.longitudePickup = null;
      this.latitudeDestination = null;
      this.longitudeDestination = null;
      this.map.scrollZoom.enable();
      this.map.dragPan.enable();
      this.map.dragRotate.enable();
      this.map.keyboard.enable();
      this.map.doubleClickZoom.enable();
      this.map.touchZoomRotate.enable();
      if (this.pickupMarker) {
        this.pickupMarker.remove();
        this.pickupMarker = null;
      }
      if (this.destinationMarker) {
        this.destinationMarker.remove();
        this.destinationMarker = null;
      }
      if (this.map && this.map.getSource('route')) {
        this.map.removeLayer('route');
        this.map.removeSource('route');
      }
      if (this.map) {
        this.map.flyTo({ center: [-4.4991, 36.6213], zoom: 8 });
      }
      this.distance = 0;
      this.estimatedPrice = 0;
      this.distanceCost = 0;
      this.waitTimeCost = 0;
      this.waitingTime = 0;
      this.routeDuration = null; // Resetear duración
      this.tripId = null; // Resetear tripId

      // Eliminar datos de localStorage
      localStorage.removeItem('Origen');
      localStorage.removeItem('Destino');
      localStorage.removeItem('FechaYHora');
      localStorage.removeItem('TipoDeViaje');
      localStorage.removeItem('VehiculoSeleccionado');
      localStorage.removeItem('MetodoDePago');
      localStorage.removeItem('LatitudOrigen');
      localStorage.removeItem('LongitudOrigen');
      localStorage.removeItem('LatitudDestino');
      localStorage.removeItem('LongitudDestino');
      localStorage.removeItem('PrecioEstimado');
      localStorage.removeItem('TripId'); // Eliminar TripId del localStorage

      this.showMessage('Búsqueda reiniciada.');
    },
    // Función para mostrar mensajes
    showMessage(message) {
      if (window.Android && typeof window.Android.showToast === 'function') {
        window.Android.showToast(message);
      } else {
        alert(message);
      }
    },
  },
  mounted() {
    this.initMap();
  },
}
</script>

<style scoped>
/* Ajustes del modal */
.modal-dialog {
  max-width: 100%;
  margin: auto;
}

.modal-fullscreen {
  width: 100%;
  height: 100%;
  margin: 0;
}

.modal-content {
  height: 100%;
}

@media (max-width: 576px) {
  .modal-dialog {
    width: 100%;
    margin: 0;
  }
}

.map-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0; /* Aseguramos que el mapa no se superponga */
}

.mapboxgl-ctrl {
  z-index: 1;
}

.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-top-left {
  top: 70px;
}

/* Estilos para la caja de precio en horizontal */
.price-details {
  background-color: #3b3b3b;
  color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  font-size: 1rem;
}

.price-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.price-item {
  flex: 1 1 45%;
  margin-bottom: 10px;
}

.price-item p {
  margin: 0;
}

/* Estilos para botones y elementos */
.btn-primary {
  background-color: #0FC938;
  border-color: #0FC938;
}

.btn-primary:hover {
  background-color: #0B9A2B;
  border-color: #0B9A2B;
}

.custom-btn-request {
  background-color: #0FC938;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.custom-btn-request:hover {
  background-color: #0B9A2B;
}

.language-switch button,
.payment-method-button.active,
.seat-options button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-color: #0FC938;
}

/* Estilos adicionales */
.custom-modal-content {
  border-radius: 10px;
}

.modal-header {
  background-color: #0FC938;
  color: #ffffff;
  border-bottom: none;
}

.modal-header .btn-close {
  background-color: #ffffff;
  opacity: 1;
}

.custom-fullscreen-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.custom-close-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
}

.language-switch button {
  border: none;
  background-color: transparent;
  margin-right: 5px;
  font-size: 1rem;
}

.language-switch button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
}

.payment-method-button {
  border: 1px solid #d3d3d3;
  background-color: #ffffff;
  color: #000000;
  padding: 10px;
  border-radius: 5px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-method-button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-color: #0FC938;
}

.seat-options {
  flex-wrap: wrap;
}

.seat-options button {
  margin-right: 10px;
  margin-bottom: 10px;
}

.seat-options button.active {
  background-color: #0FC938;
  color: #ffffff;
  border-color: #0FC938;
}

.price-details .price-info p {
  margin-bottom: 5px;
}

/* Responsividad */
@media (max-width: 991px) {
  .modal-dialog {
    width: 100%;
  }
  .seat-options {
    flex-direction: column;
  }
  .seat-options button {
    margin-bottom: 10px;
  }
}

.price-details h5 {
  color: #15f746; /* Cambia este color al que prefieras */
}

.input-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  left: 10px;
  pointer-events: auto;
}

#pickupLocation {
  padding-left: 35px;
}

/* Clase para ajustar el tamaño del input de fecha y hora */
.small-datetime-input {
  max-width: 200px; /* Ajusta este valor según tus necesidades */
}
</style>

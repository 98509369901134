// src/i18n.js
import { reactive } from 'vue';

export const i18n = reactive({
    language: 'es', // Idioma inicial
    translations: {
        es: {
            subtitle: 'EXPERIENCIA PREMIUM',
            title: 'Descubre la Comodidad de Green Transfer',
            description: 'Ofrecemos un servicio de traslado exclusivo y confiable en la Costa del Sol, ideal para aquellos que desean disfrutar de sus vacaciones con tranquilidad y estilo.',
            airportTransfer: {
                title: 'Traslados desde el Aeropuerto',
                description: 'Nos encargamos de tu traslado desde el aeropuerto hasta las zonas turísticas, asegurando comodidad y puntualidad.',
            },
            premiumService: {
                title: 'Servicio Premium para Turistas',
                description: 'Disfruta de un servicio de transporte premium y seguro en tus vacaciones, con conductores de confianza y experiencia.',
            },
            requestNow: 'SOLICITAR AHORA',
            services: {
                subtitle: 'Servicios VTC de Green Transfer',
                heading: 'Explora Nuestros Servicios Exclusivos',
                premiumService: {
                    title: 'Servicio VTC Premium',
                    shortDesc: 'Confort y exclusividad en cada traslado.',
                    list1: 'Traslados estándar en la Costa del Sol.',
                    list2: 'Experiencia superior con vehículos de lujo.',
                },
                easyBooking: {
                    title: 'Reserva Fácil y Rápida',
                    shortDesc: 'Proceso simplificado y soporte personalizado.',
                    list1: 'Realiza reservas de manera rápida y segura.',
                    list2: 'Equipo de soporte disponible para asistencia.',
                },
                explore: {
                    title: 'Explora la Costa del Sol',
                    shortDesc: 'Rutas turísticas y viajes personalizados.',
                    list1: 'Explora playas y sitios turísticos.',
                    list2: 'Conductores con conocimientos locales.',
                },
                startNow: 'COMENZAR',
            },
            footer: {
                address: 'Dirección',
                location: 'Ubicación Global',
                aiPowered: 'Green Transfer Funciona con modelos de IA',
                aboutUs: 'Sobre nosotros',
                token: 'Token',
                joinUs: 'Únete a Nosotros',
                mobilityPack: 'Paquete de movilidad',
                services: 'Nuestros Servicios',
                copyright: '© {year} Greentransfer',
            }
        },
        en: {
            subtitle: 'PREMIUM EXPERIENCE',
            title: 'Discover the Comfort of Green Transfer',
            description: 'We offer an exclusive and reliable transfer service on the Costa del Sol, ideal for those who want to enjoy their vacation with peace and style.',
            airportTransfer: {
                title: 'Airport Transfers',
                description: 'We take care of your transfer from the airport to tourist areas, ensuring comfort and punctuality.',
            },
            premiumService: {
                title: 'Premium Service for Tourists',
                description: 'Enjoy a premium and safe transport service during your vacation, with trusted and experienced drivers.',
            },
            requestNow: 'REQUEST NOW',
            services: {
                subtitle: 'VTC Services by Green Transfer',
                heading: 'Explore Our Exclusive Services',
                premiumService: {
                    title: 'Premium VTC Service',
                    shortDesc: 'Comfort and exclusivity in every transfer.',
                    list1: 'Standard transfers on the Costa del Sol.',
                    list2: 'Superior experience with luxury vehicles.',
                },
                easyBooking: {
                    title: 'Easy and Fast Booking',
                    shortDesc: 'Simplified process and personalized support.',
                    list1: 'Book quickly and securely.',
                    list2: 'Support team available for assistance.',
                },
                explore: {
                    title: 'Explore the Costa del Sol',
                    shortDesc: 'Tourist routes and personalized trips.',
                    list1: 'Discover beaches and tourist sites.',
                    list2: 'Drivers with local knowledge.',
                },
                startNow: 'START NOW',
            },
            footer: {
                address: 'Address',
                location: 'Global Location',
                aiPowered: 'Green Transfer Powered by AI Models',
                aboutUs: 'About us',
                token: 'Token',
                joinUs: 'Join Us',
                mobilityPack: 'Mobility Pack',
                services: 'Our Services',
                copyright: '© {year} Greentransfer',
            }
        },
        nl: {
            subtitle: 'PREMIUM ERVARING',
            title: 'Ontdek het Comfort van Green Transfer',
            description: 'Wij bieden een exclusieve en betrouwbare transferservice aan de Costa del Sol, ideaal voor wie wil genieten van een ontspannen en stijlvolle vakantie.',
            airportTransfer: {
                title: 'Luchthaven Transfers',
                description: 'Wij verzorgen uw transfer van de luchthaven naar toeristische gebieden, met comfort en stiptheid gegarandeerd.',
            },
            premiumService: {
                title: 'Premium Service voor Toeristen',
                description: 'Geniet van een premium en veilige transportservice tijdens uw vakantie, met betrouwbare en ervaren chauffeurs.',
            },
            requestNow: 'NU AANVRAGEN',
            services: {
                subtitle: 'VTC-diensten van Green Transfer',
                heading: 'Ontdek Onze Exclusieve Diensten',
                premiumService: {
                    title: 'Premium VTC-dienst',
                    shortDesc: 'Comfort en exclusiviteit bij elke rit.',
                    list1: 'Standaardtransfers aan de Costa del Sol.',
                    list2: 'Superieure ervaring met luxe voertuigen.',
                },
                easyBooking: {
                    title: 'Gemakkelijke en Snelle Boeking',
                    shortDesc: 'Vereenvoudigd proces en persoonlijke ondersteuning.',
                    list1: 'Boek snel en veilig.',
                    list2: 'Ondersteuningsteam beschikbaar voor hulp.',
                },
                explore: {
                    title: 'Verken de Costa del Sol',
                    shortDesc: 'Toeristische routes en aangepaste reizen.',
                    list1: 'Ontdek stranden en toeristische plekken.',
                    list2: 'Chauffeurs met lokale kennis.',
                },
                startNow: 'BEGIN NU',
            },
            footer: {
                address: 'Adres',
                location: 'Globale Locatie',
                aiPowered: 'Green Transfer Aangedreven door AI-modellen',
                aboutUs: 'Over ons',
                token: 'Token',
                joinUs: 'Doe mee',
                mobilityPack: 'Mobiliteitspakket',
                services: 'Onze Diensten',
                copyright: '© {year} Greentransfer',
            }
        },
    },
    setLanguage(lang) {
        this.language = lang;
    },
});

export function t(key) {
    const keys = key.split('.');
    let value = i18n.translations[i18n.language];
    for (const k of keys) {
        value = value[k];
        if (!value) return key; // Retorna la clave si no se encuentra traducción
    }
    return value;
}

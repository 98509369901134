<template>
    <div class="one-faq-area two-faq-area pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5">
                    <div class="faq-img-two">
                        <img src="../../assets/img/home-two/faq.jpg" alt="FAQ">
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="faq-content">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a class="faq-title">¿Por qué elegir Green Transfer?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Green Transfer garantiza puntualidad y confiabilidad, nuestros conductores estarán siempre a tiempo para recibirte, sin importar retrasos en tu vuelo. Además, contamos con precios transparentes (todos los peajes están incluidos) y ofrecemos cancelación gratuita hasta 48 horas antes del traslado. ¡Nuestro equipo está disponible 24/7 para cualquier consulta o necesidad!
                                    </p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a class="faq-title">¿Cuáles son los tipos de vehículos disponibles y sus precios?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Green Transfer cuenta con una variedad de opciones para ajustarse a tus necesidades:
                                        <ul>
                                            <li>Express Shuttle: Desde 3.23€</li>
                                            <li>Taxi: Desde 7.21€</li>
                                            <li>Coche VIP: Desde 21.61€</li>
                                            <li>Minivan: Desde 12.09€</li>
                                        </ul>
                                        Reserva ahora y disfruta de un traslado rápido, seguro y sin complicaciones.
                                    </p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a class="faq-title">¿Cómo puedo reservar un traslado con Green Transfer?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Reservar es fácil y rápido en tres sencillos pasos:
                                        <ol>
                                            <li>Selecciona origen y destino en la Costa del Sol, incluyendo el Aeropuerto de Málaga y 20 localidades principales.</li>
                                            <li>Indica la fecha y hora de llegada o salida, y nuestro conductor estará puntualmente allí.</li>
                                            <li>Selecciona el número de pasajeros y el tipo de vehículo que prefieras.</li>
                                        </ol>
                                    </p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a class="faq-title">¿Por qué reservar con Green Transfer?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Al reservar con Green Transfer, puedes disfrutar de precios transparentes, cancelación gratuita hasta 48 horas antes del traslado, y servicio disponible las 24 horas del día, todos los días de la semana.
                                    </p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a class="faq-title">¿Cuáles son los precios aproximados para traslados populares?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Consulta algunos de nuestros precios:
                                        <ul>
                                            <li>Málaga Aeropuerto - Marbella: Desde 26 €</li>
                                            <li>Málaga Aeropuerto - Benalmádena: Desde 26 €</li>
                                            <li>Málaga Aeropuerto - Torremolinos: Desde 26 €</li>
                                        </ul>
                                    </p>
                                </template>
                            </accordion-item>

                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <a class="faq-title">¿Qué opciones de vehículos ofrece Green Transfer?</a>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Green Transfer dispone de una flota moderna y cómoda para cualquier necesidad:
                                        <ul>
                                            <li><strong>Coches Sedán y VIP:</strong> Ideales para privacidad y lujo, con todas las comodidades para ejecutivos y ocasiones especiales.</li>
                                            <li><strong>Minivans y Monovolúmenes:</strong> Perfectas para grupos pequeños y familias, con espacio adicional para equipaje.</li>
                                            <li><strong>Autobuses y Microbuses:</strong> Opción ideal para grupos grandes, eventos o excursiones, con climatización y asientos reclinables.</li>
                                            <li><strong>Vehículos Adaptados:</strong> Diseñados para garantizar un traslado cómodo y accesible para todos.</li>
                                        </ul>
                                    </p>
                                </template>
                            </accordion-item>
                        </accordion>

                        <!-- Botón de reserva -->
                        <div class="reservation-button">
                            <router-link :to="{ path: '/mobile/request-trip' }" class="request-now-button">
                                Reserva Ahora
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { i18n } from '@/i18n';
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    },
    setup() {
        const setLanguage = (lang) => {
            i18n.setLanguage(lang);
        };

        return {
            i18n,
            setLanguage,
        };
    }
}
</script>

<style scoped>
.faq-title {
    font-size: 1.2rem; /* Ajusta el tamaño de fuente del título */
}
.reservation-button {
    margin-top: 20px;
    text-align: center;
}
.request-now-button {
    padding: 10px 20px;
    background-color: #0FC938;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
}
.request-now-button:hover {
    background-color: #0BAF30;
}
</style>

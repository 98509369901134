

<template>
    <div class="one-among-area two-among-area pt-100 pb-70" id="service">
        <div class="container">
            <!-- Selector de idioma usando el componente Language -->
            <Language :styleType="'language-selector'" />

            <div class="one-section-title">
                <span class="sub-title">¿POR QUÉ ELEGIRNOS?</span>
                <h3>Green Transfer: Tu Mejor Opción de Traslado en la Costa del Sol</h3>
                <p>
                    Descubre el servicio de traslados más confiable y cómodo desde el Aeropuerto de Málaga hacia cualquier destino en la Costa del Sol. 
                    Green Transfer cubre diversas zonas con su encanto único, desde las playas hasta la gastronomía local. 
                    Te ofrecemos traslados privados y compartidos en las principales áreas de la región:
                </p>
            </div>
            <div class="row">
                <!-- Generar cada zona usando v-for -->
                <div class="col-sm-6 col-lg-4" v-for="zona in zonas" :key="zona.id">
                    <div class="among-item">
                        <div class="among-top">
                            <img :src="require(`@/assets/img/green/driversandcars/portada/${zona.imagen}`)" :alt="'Zona ' + zona.nombre">
                            <div class="among-icon">
                                <!-- Icono específico para cada zona usando Boxicons -->
                                <i :class="zona.icono"></i>
                            </div>
                        </div>
                        <div class="among-bottom">
                            <h3>{{ zona.nombre }}</h3>
                            <p>{{ zona.descripcion }}</p>
                            <p>{{ zona.detalle }}</p>
                            <router-link :to="{ path: '/mobile/request-trip', query: { zonaId: zona.id } }" class="request-now-button">
                                Solicitar viaje ahora
                                <i class="bx bx-right-arrow-alt"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Language from '@/components/MainPage/Language.vue'; // Importamos el componente Language
import 'boxicons'; // Importar Boxicons globalmente
export default {
    name: 'WeHaveOffer',
    components: {
        Language, // Registramos el componente
    },
    data() {
        return {
            zonas: [
                {
                    id: 1,
                    nombre: 'Zona 1: Marbella y Alrededores',
                    descripcion: 'Marbella, Estepona, Manilva, Casares, San Pedro de Alcántara, Benahavís',
                    detalle: 'Disfruta de las lujosas playas de Marbella y su famosa "Milla de Oro", así como de la autenticidad de los pueblos blancos de Casares y Benahavís, donde encontrarás platos típicos andaluces como el gazpacho y pescados frescos.',
                    imagen: 'landing.png',
                    icono: 'bx bx-map',
                },
                {
                    id: 2,
                    nombre: 'Zona 2: Costa Central',
                    descripcion: 'Fuengirola, Mijas, La Cala de Mijas, Benalmádena, Torremolinos, Alhaurín el Grande',
                    detalle: 'Esta zona es perfecta para disfrutar de playas de arena dorada y una vibrante vida nocturna. La Cala de Mijas es conocida por sus playas y restaurantes de mariscos, mientras que Mijas ofrece vistas impresionantes y encanto tradicional.',
                    imagen: 'villa.png',
                    icono: 'bx bx-car',
                },
                {
                    id: 3,
                    nombre: 'Zona 3: Málaga y su Costa',
                    descripcion: 'Málaga, Rincón de la Victoria, Vélez-Málaga, Torrox, Nerja, Almuñécar',
                    detalle: 'Conocida por su historia y cultura, Málaga ofrece museos y una rica gastronomía. Rincón de la Victoria es perfecto para relajarse en sus playas, mientras que Nerja sorprende con sus cuevas y playas de aguas cristalinas.',
                    imagen: 'paisaje.png',
                    icono: 'bx bx-sun',
                },
            ],
        };
    },
};
</script>

<style scoped>
/* Botón de solicitud de viaje */
.request-now-button {
    padding: 10px 20px;
    background-color: #0FC938;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
    margin-top: 15px;
    cursor: pointer;
}
.request-now-button:hover {
    background-color: #0BAF30;
}

/* Icono en cada zona */
.among-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #3CB371;
}
.among-icon i {
    font-size: 1.8rem;
    color: white;
}

/* Ajustes para evitar que el texto se corte */
.among-bottom h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
}

.among-bottom p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.5;
    text-align: justify;
}

/* Diseño responsivo */
@media (max-width: 768px) {
    .request-now-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
    .among-icon {
        height: 50px;
        width: 50px;
    }
    .among-icon i {
        font-size: 1.5rem;
    }
    .among-bottom h3 {
        font-size: 1.2rem;
    }
    .among-bottom p {
        font-size: 0.9rem;
    }
}
</style>

<!-- src/components/MainPage/RequestTravel.vue -->
<template>
  <div class="trip-container">
    <div class="trip-card">
      <!-- Encabezado del Viaje con Emojis -->
      <div class="trip-header">
        <span class="emoji" role="img" aria-label="Origen">📍</span>
        <span class="location">{{ trip.pickup_location }}</span>
        <span class="separator">→</span>
        <span class="emoji" role="img" aria-label="Destino">📌</span>
        <span class="location">{{ trip.dropoff_location }}</span>
      </div>

      <!-- Detalles del Viaje -->
      <div class="trip-details">
        <p><strong>Teléfono del Pasajero:</strong> {{ trip.passenger_phone }}</p>
        <p><strong>Tipo de Vehículo:</strong> {{ trip.vehicle_type }}</p>
        <p><strong>Programado:</strong> {{ trip.is_scheduled ? '✔️' : '❌' }}</p>
        <p><strong>Pagado:</strong> {{ trip.is_paid ? '✔️' : '❌' }}</p>
        <p><strong>Realizado:</strong> {{ trip.is_completed ? '✔️' : '❌' }}</p>
        <p><strong>Conductor Asignado:</strong> {{ trip.driver_id ? '✔️' : '❌' }}</p>
        <p><strong>Fecha y Hora de Registro:</strong> {{ trip.registered_at }}</p>
        <p><strong>Método de Pago:</strong> {{ trip.payment_method === 'Cash' ? 'Efectivo' : 'Tarjeta' }}</p>
        <p><strong>Distancia:</strong> {{ trip.distance_km }} km</p>
        <p><strong>Precio:</strong> {{ trip.estimated_price }} €</p>
      </div>

      <!-- Botones de Acción -->
      <div class="action-buttons">
        <button @click="acceptTrip" class="btn accept-btn">Aceptar Viaje</button>
        <button @click="addTrip" class="btn add-btn">Añadir un Viaje</button>
        <button @click="shareTrip" class="btn share-btn">Compartir en WhatsApp</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestTravel",
  data() {
    return {
      trip: {
        pickup_location: "Málaga Centro",
        dropoff_location: "Marbella",
        passenger_phone: "123456789",
        vehicle_type: "PREMIUM",
        is_scheduled: true,
        is_paid: true,
        is_completed: false,
        driver_id: 1,
        registered_at: "2024-10-31 14:30",
        payment_method: "Cash",
        distance_km: 60,
        estimated_price: 45
      }
    };
  },
  methods: {
    acceptTrip() {
      alert("Has aceptado el viaje.");
    },
    addTrip() {
      alert("Nuevo viaje añadido.");
    },
    shareTrip() {
      const whatsappUrl = `https://wa.me/?text=Green Transfer: Viaje de ${this.trip.pickup_location} a ${this.trip.dropoff_location}%0ADistancia: ${this.trip.distance_km} km | Precio: ${this.trip.estimated_price}€ | Hora de salida: ${this.trip.registered_at}%0AReserva aquí: https://enlace.dummy%0A(Gana una comisión de hasta un 35% por los viajes que no hagas)`;
      window.open(whatsappUrl, "_blank");
    }
  }
};
</script>

<style scoped>
.trip-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.trip-card {
  width: 100%;
  max-width: 600px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

.trip-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.emoji {
  font-size: 24px;
  margin: 0 8px;
}

.location {
  font-size: 18px;
  font-weight: bold;
}

.separator {
  font-size: 24px;
  color: #333;
  margin: 0 8px;
}

.trip-details p {
  margin: 8px 0;
  font-size: 16px;
  color: #555;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  width: 30%;
  transition: background-color 0.3s ease;
}

/* Estilos de los botones con color #0FC938 */
.accept-btn {
  background-color: #0FC938;
}

.add-btn {
  background-color: #0FC938;
}

.share-btn {
  background-color: #0FC938;
}

/* Efecto hover para los botones */
.btn:hover {
  background-color: #0db62b; /* Un verde más oscuro para el hover */
}
</style>

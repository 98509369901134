<template>
    <div id="home" class="two-banner-wrap">
        <div class="two-banner-slider">
            <carousel 
                :autoplay="5000"
                :wrap-around="true"
            >
                <slide v-for="slide in carouselItems" :key="slide.id">
                    <div :class="slide.class">
                        <div class="d-table">
                            <div class="d-table-cell">
                                <div class="container">
                                    <div class="banner-content">
                                        <span>
                                            <img :src="slide.image" alt="Banner">
                                            {{ slide.subTitle }}
                                        </span>
                                        <h1>{{ slide.heading }}</h1>
                                        <div class="banner-btn">
                                            <a class="cmn-banner-btn" href="#service">
                                                <i class='bx bx-note'></i>
                                                Descubre más sobre nosotros
                                            </a>
                                            <a class="banner-right-btn" href="#info">
                                                <i class='bx bx-note'></i>
                                                Preguntas frecuentes
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>

                <template #addons>
                    <Navigation />
                </template>
            </carousel>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 p-0">
                    <div class="banner-time-left">
                        <ul>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <span>24h Atención</span>
                                <a href="tel:+34635787157">+34635787157</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 p-0">
                    <div class="banner-time-right">
                        <ul>
                            <li>
                                <i class='bx bx-timer'></i>
                                <span>Horario de recepción:</span>
                                <p>9:30 a 14:00 y 16:00 a 19:30</p>
                            </li>
                            <li>
                                <i class='bx bxs-contact'></i>
                                <span>Contáctanos </span>
                                <a href="mailto:contact@greentransfer.com">contact@greentransfer.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Navigation, Slide } from 'vue3-carousel';

export default {
    name: 'MainBanner',
    components: {
        Carousel,
        Slide,
        Navigation
    },
    data() {
        return {
            carouselItems: [
                {
                    id: 1,
                    class: 'two-banner-area two-banner-one',
                    image: require('../../assets/img/green/logoban.png'),
                    subTitle: 'Bienvenido a Green Transfer',
                    heading: 'Innovación en cada trayecto',
                },
                {
                    id: 2,
                    class: 'two-banner-area two-banner-two',
                    image: require('../../assets/img/green/logoban.png'),
                    subTitle: 'Viajes Sostenibles',
                    heading: 'Movilidad ecológica y eficiente',
                },
                {
                    id: 3,
                    class: 'two-banner-area two-banner-three',
                    image: require('../../assets/img/green/logoban.png'),
                    subTitle: 'Conexión Global',
                    heading: 'Viajes seguros y confiables',
                },
            ],
        };
    },
};
</script>

<style scoped>
.two-banner-wrap {
    position: relative;
}

.two-banner-slider {
    position: relative;
    overflow: hidden;
}

.banner-content {
    text-align: center;
    color: #fff;
    max-width: 800px;
    margin: 0 auto;
}

.banner-content h1 {
    font-size: 3rem;
    line-height: 1.2;
    margin-top: 20px;
    word-break: break-word;
}

.banner-content span {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 10px;
    color: #ccc;
}

.banner-btn a {
    display: inline-block;
    margin: 10px 5px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #0FC938;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.banner-btn a:hover {
    background-color: #0BAF30;
}

.d-table {
    display: table;
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .banner-content h1 {
        font-size: 2rem;
    }

    .banner-content span {
        font-size: 1.2rem;
    }
}
</style>

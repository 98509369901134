<template>
  <div class="container">
    <table class="trips-table">
      <thead>
        <tr>
          <th>Ubicación de Recogida</th>
          <th>Ubicación de Destino</th>
          <th>Teléfono del Pasajero</th>
          <th>Tipo de Vehículo</th>
          <th>Programado</th>
          <th>Cancelado</th>
          <th>Conductor Asignado</th>
          <th>Fecha y Hora de Recogida</th>
          <th>Fecha y Hora de Destino</th>
          <th>Distancia (km)</th>
          <th>Precio Estimado (€)</th>
          <th>Precio Total (€)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="trip in trips" :key="trip.id">
          <td>{{ trip.pickup_location }}</td>
          <td>{{ trip.dropoff_location }}</td>
          <td>{{ trip.passenger_phone }}</td>
          <td :style="{ color: getVehicleColor(trip.vehicle_type) }">{{ trip.vehicle_type }}</td>
          <td>
            <span :class="{'status-icon': true, 'scheduled': trip.is_scheduled, 'not-scheduled': !trip.is_scheduled}">
              {{ trip.is_scheduled ? '✔' : '✖' }}
            </span>
          </td>
          <td>
            <span :class="{'status-icon': true, 'canceled': trip.is_canceled, 'not-canceled': !trip.is_canceled}">
              {{ trip.is_canceled ? '✔' : '✖' }}
            </span>
          </td>
          <td>
            <span :class="{'status-icon': true, 'driver-assigned': trip.driver_id, 'no-driver': !trip.driver_id}">
              {{ trip.driver_id ? '✔' : '✖' }}
            </span>
          </td>
          <td>{{ formatDateTime(trip.pickup_datetime) }}</td>
          <td>{{ formatDateTime(trip.dropoff_datetime) }}</td>
          <td>{{ trip.distance_km }}</td>
          <td>{{ trip.estimated_price }}</td>
          <td>{{ trip.total_price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      trips: []
    };
  },
  methods: {
    getVehicleColor(type) {
      switch (type) {
        case "PREMIUM":
          return "blue";
        case "SAVE":
          return "green";
        case "VAN":
          return "purple";
        default:
          return "grey";
      }
    },
    formatDateTime(datetime) {
      if (!datetime) return '-';
      const options = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', second: '2-digit'
      };
      return new Date(datetime).toLocaleDateString(undefined, options);
    }
  },
  mounted() {
    fetch("http://127.0.0.1:8000/api/trips/")
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        this.trips = data;
      })
      .catch(error => console.error("Error cargando los datos de viajes:", error));
  }
};
</script>

<style>
.container {
  max-width: 1000px;
  margin: 0 auto;
}

.trips-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.trips-table th,
.trips-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
}

.trips-table th {
  background-color: #f4f4f4;
}

.status-icon {
  font-size: 16px;
  font-weight: bold;
}

.scheduled,
.completed,
.driver-assigned,
.canceled {
  color: green;
}

.not-scheduled,
.not-completed,
.no-driver,
.not-canceled {
  color: red;
}

.paid {
  color: green;
}

.not-paid {
  color: grey;
}
</style>

<template>
  <div class="container my-5">
    <div class="row">
      <!-- Columna Izquierda: Detalles del Viaje -->
      <div class="col-md-6">
        <div class="trip-details">
          <h3 class="mb-4">Detalles del Viaje</h3>
          <ul class="list-group list-unstyled">
            <li><strong>Origen:</strong> {{ tripDetails.origin }}</li>
            <li><strong>Destino:</strong> {{ tripDetails.destination }}</li>
            <li><strong>Fecha y Hora:</strong> {{ formattedDateTime }}</li>
            <li><strong>Tipo de Viaje:</strong> {{ tripDetails.tripType }}</li>
            <li><strong>Vehículo Seleccionado:</strong> {{ tripDetails.vehicleSelected }}</li>
          </ul>
          <div class="map-container mt-4" ref="summaryMap"></div>
        </div>
      </div>

      <!-- Columna Derecha -->
      <div class="col-md-6">
        <div v-if="!paymentProcessed">
          <div class="payment-section">
            <h3 class="mb-4">Resumen del Pago</h3>
            <div class="payment-summary mb-4">
              <ul class="list-group">
                <li class="list-group-item d-flex justify-content-between align-items-center">
                  <span><strong>Precio Estimado:</strong></span>
                  <span class="price">€{{ estimatedPrice.toFixed(2) }}</span>
                </li>
              </ul>
              <p class="mt-2">
                <strong>Nota:</strong> El precio mostrado es una estimación. El precio final puede variar según el tráfico y otros factores.
              </p>
            </div>

            <!-- Mensaje Condicional para Pago en Efectivo -->
            <div v-if="tripDetails.paymentMethod === 'Cash'" class="alert alert-warning" role="alert">
              Este cobro sólo será un depósito, que será liberado una vez confirmado el pago al conductor.
            </div>

            <!-- Formulario de Pago con Stripe Elements -->
            <form @submit.prevent="submitPayment">
              <div class="mb-3">
                <label for="card-element" class="form-label">Detalles de la Tarjeta</label>
                <div id="card-element" class="form-control">
                  <!-- Stripe Elements montará aquí el elemento de la tarjeta -->
                </div>
                <!-- Muestra errores de validación de tarjeta -->
                <div id="card-errors" role="alert" class="text-danger mt-2"></div>
              </div>

              <div class="mb-3">
                <label for="cardHolder" class="form-label">Titular de la Tarjeta</label>
                <div class="input-group input-group-sm">
                  <span class="input-group-text">
                    <i class="mdi mdi-account-outline"></i>
                  </span>
                  <input
                    type="text"
                    class="form-control"
                    id="cardHolder"
                    v-model="cardHolder"
                    placeholder="Nombre Completo"
                    required
                  />
                </div>
              </div>

              <button type="submit" class="btn btn-primary w-100 solicitar-viaje-btn">
                Solicitar Viaje
              </button>
            </form>

            <!-- Iconos de confianza -->
            <div class="trust-icons text-center my-4">
              <div class="d-flex justify-content-center align-items-center gap-3 mb-3 payment-icons">
                <img src="https://logos-world.net/wp-content/uploads/2020/04/Visa-Logo.png" alt="Visa" class="payment-icon" />
                <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png" alt="MasterCard" class="payment-icon" />
              </div>
              <div class="secure-payment">
                <i class="mdi mdi-lock"></i> Pago Seguro
              </div>
              <p class="text-muted small mt-2">
                Sus datos están protegidos con encriptación avanzada.
              </p>
            </div>
          </div>
        </div>

        <!-- Animación de Asignación de Vehículo -->
        <div v-else>
          <div class="animation-section">
            <h3 class="mb-4 text-center">Asignando tu Vehículo</h3>
            <ul class="timeline">
              <li
                v-for="(step, index) in animationSteps"
                :key="index"
                :class="{ active: currentAnimationStep >= index }"
              >
                <div class="timeline-icon">
                  <i :class="step.icon"></i>
                </div>
                <div class="timeline-content">
                  <p>{{ step.message }}</p>
                </div>
                <div class="timeline-check" v-if="currentAnimationStep > index">
                  <i class="mdi mdi-check-circle-outline"></i>
                </div>
              </li>
            </ul>
            <!-- Iconos Vectoriales -->
            <div class="vector-images">
              <i class="mdi mdi-car vector-icon"></i>
              <i class="mdi mdi-steering vector-icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css';
import axios from 'axios';
import maplibregl from 'maplibre-gl';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'Pagos',
  data() {
    return {
      estimatedPrice: 0.0,
      tripDetails: {
        origin: localStorage.getItem('Origen') || 'Origen no especificado',
        destination: localStorage.getItem('Destino') || 'Destino no especificado',
        latitudePickup: parseFloat(localStorage.getItem('LatitudOrigen')) || null,
        longitudePickup: parseFloat(localStorage.getItem('LongitudOrigen')) || null,
        latitudeDestination: parseFloat(localStorage.getItem('LatitudDestino')) || null,
        longitudeDestination: parseFloat(localStorage.getItem('LongitudDestino')) || null,
        dateTime: localStorage.getItem('FechaYHora') || null,
        tripType: localStorage.getItem('TipoDeViaje') || 'No especificado',
        vehicleSelected: localStorage.getItem('VehiculoSeleccionado') || 'No especificado',
        paymentMethod: localStorage.getItem('MetodoDePago') || 'Not specified',
      },
      map: null,
      animationInterval: null,
      routeLayerId: 'route',
      cardHolder: '',
      paymentProcessed: false,
      currentAnimationStep: -1,
      animationSteps: [],
      stripe: null,
      elements: null,
      card: null,
    };
  },
  created() {
    const getRandomDuration = () => Math.floor(Math.random() * 7) + 1;

    this.animationSteps = [
      {
        message: 'Estamos buscando los mejores conductores en tu zona',
        duration: getRandomDuration(),
        icon: 'mdi mdi-map-search-outline',
      },
      {
        message: 'Asignando tu conductor',
        duration: getRandomDuration(),
        icon: 'mdi mdi-account-check-outline',
      },
      {
        message: 'Notificando tu posición al conductor',
        duration: getRandomDuration(),
        icon: 'mdi mdi-map-marker-radius',
      },
      {
        message: 'Preparando la ruta óptima',
        duration: getRandomDuration(),
        icon: 'mdi mdi-map-outline',
      },
      {
        message: '¡Tu conductor está en camino!',
        duration: getRandomDuration(),
        icon: 'mdi mdi-car-arrow-right',
      },
    ];
  },
  async mounted() {
    this.estimatedPrice = parseFloat(localStorage.getItem('PrecioEstimado')) || 0.0;
    this.initMap();

    // Inicializar Stripe
    this.stripe = await loadStripe('pk_test_51IzByQCM2RQN2snqU3FHG5huCPCUGujIwDSuN00aksxgKE15KB3WR2ZEfuGGOewgv3motYEcNym9YdAZOY9fcBhO00pIpfjvG6'); // Reemplaza con tu clave publicable real

    // Inicializar Stripe Elements
    this.elements = this.stripe.elements();

    // Crear y montar el elemento de la tarjeta
    this.card = this.elements.create('card', {
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    });
    this.card.mount('#card-element');

    // Manejar cambios en el elemento de la tarjeta para mostrar errores
    this.card.on('change', (event) => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  },
  beforeUnmount() {
    if (this.card) {
      this.card.destroy();
    }
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
    }
  },
  methods: {
    initMap() {
      const { latitudePickup, longitudePickup, latitudeDestination, longitudeDestination } = this.tripDetails;

      if (latitudePickup && longitudePickup && latitudeDestination && longitudeDestination) {
        this.map = new maplibregl.Map({
          container: this.$refs.summaryMap,
          style: 'https://tiles.stadiamaps.com/styles/outdoors.json',
          center: [(longitudePickup + longitudeDestination) / 2, (latitudePickup + latitudeDestination) / 2],
          zoom: 12,
          interactive: false,
        });

        this.map.on('load', () => {
          this.getRouteAndAnimate();
        });
      }
    },
    async getRouteAndAnimate() {
      const { latitudePickup, longitudePickup, latitudeDestination, longitudeDestination } = this.tripDetails;

      try {
        const response = await fetch(
          `https://router.project-osrm.org/route/v1/driving/${longitudePickup},${latitudePickup};${longitudeDestination},${latitudeDestination}?overview=full&geometries=geojson`
        );
        const data = await response.json();

        if (data.routes && data.routes.length > 0) {
          const route = data.routes[0];
          const routeGeoJSON = {
            type: 'Feature',
            geometry: route.geometry,
          };

          this.map.addSource('route', {
            type: 'geojson',
            data: routeGeoJSON,
          });

          this.map.addLayer({
            id: this.routeLayerId,
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#0FC938',
              'line-width': 5,
            },
          });

          const bounds = new maplibregl.LngLatBounds();
          route.geometry.coordinates.forEach((coord) => bounds.extend(coord));
          this.map.fitBounds(bounds, { padding: 50 });

          this.animateRoute(route.geometry.coordinates);
        }
      } catch (error) {
        console.error('Error al obtener la ruta:', error);
      }
    },
    animateRoute(coordinates) {
      const source = {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
      };

      this.map.addSource('animatedRoute', source);

      const layer = {
        id: 'animatedRoute',
        type: 'circle',
        source: 'animatedRoute',
        paint: {
          'circle-radius': 7,
          'circle-color': '#0FC938',
        },
      };

      this.map.addLayer(layer);

      let index = 0;

      const animate = () => {
        if (index >= coordinates.length) {
          index = 0;
        }

        source.data.features = [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: coordinates[index],
            },
          },
        ];

        this.map.getSource('animatedRoute').setData(source.data);
        index++;
      };

      this.animationInterval = setInterval(animate, 100);
    },
    async submitPayment() {
      const tripId = localStorage.getItem('TripId');
      if (!tripId) {
        console.error('No se encontró TripId en localStorage.');
        alert('No se pudo encontrar el ID del viaje. Por favor, vuelve a solicitar el viaje.');
        return;
      }

      const paymentMethod = this.tripDetails.paymentMethod;
      let method = '';
      if (paymentMethod === 'Card') {
        method = 'Card';
      } else if (paymentMethod === 'Cash') {
        method = 'Cash';
      } else {
        method = 'Not specified';
      }

      // Obtener el monto en centavos (calculado en el backend)
      const amountInCents = Math.round(this.estimatedPrice * 100);

      try {
        // Crear el PaymentIntent en el backend
        const response = await axios.post('http://127.0.0.1:8000/api/process_payment/', {
          trip_id: tripId,
          payment_method_type: method,
        });

        const clientSecret = response.data.client_secret;

        // Confirmar el PaymentIntent en el frontend
        const { error, paymentIntent } = await this.stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.cardHolder.trim() || 'Cliente',
            },
          },
        });

        if (error) {
          console.error('Error en la confirmación del pago:', error);
          alert('Error en la confirmación del pago. Por favor, inténtalo de nuevo.');
        } else if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'requires_capture') {
          // Notificar al backend para actualizar el estado del pago
          await axios.post('http://127.0.0.1:8000/api/update_payment_status/', {
            payment_intent_id: paymentIntent.id,
            status: paymentIntent.status === 'succeeded' ? 'Completed' : 'Pending Capture',
          });

          this.paymentProcessed = true;
          this.startAnimation();
        }
      } catch (error) {
        console.error('Error al procesar el pago:', error);
        alert('Error al procesar el pago. Por favor, inténtalo de nuevo.');
      }
    },
    resetPaymentForm() {
      this.cardHolder = '';
      if (this.card) {
        this.card.clear();
      }
    },
    startAnimation() {
      this.currentAnimationStep = -1;
      this.showNextAnimationStep();
    },
    showNextAnimationStep() {
      this.currentAnimationStep++;
      if (this.currentAnimationStep < this.animationSteps.length) {
        const step = this.animationSteps[this.currentAnimationStep];
        setTimeout(() => {
          this.showNextAnimationStep();
        }, step.duration * 1000);
      } else {
        // Animación completada
        // Puedes redirigir al usuario o mostrar un mensaje final aquí
      }
    },
  },
  computed: {
    formattedDateTime() {
      const dateTime = this.tripDetails.dateTime;
      const date = new Date(dateTime);
      if (isNaN(date.getTime())) {
        return new Date().toLocaleString('es-ES', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      }
      return date.toLocaleString('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
};
</script>

<style scoped>
@import '@mdi/font/css/materialdesignicons.css';

.map-container {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
}
.price {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0fc938;
}
.payment-icon {
  width: 50px;
  height: auto;
}
.payment-icons img {
  width: 60px;
}
.secure-payment {
  color: #0fc938;
  font-weight: bold;
  font-size: 1.2rem;
}
.solicitar-viaje-btn {
  background-color: #0fc938;
  border-color: #0fc938;
  padding: 10px;
  font-size: 1rem;
}
.solicitar-viaje-btn:hover {
  background-color: #0da32f;
  border-color: #0da32f;
}
.animation-section {
  padding: 20px;
}
.timeline {
  list-style: none;
  padding: 0;
  position: relative;
}
.timeline::before {
  content: '';
  position: absolute;
  left: 30px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #e9ecef;
}
.timeline li {
  position: relative;
  margin-bottom: 20px;
  padding-left: 60px;
}
.timeline li.active .timeline-icon {
  background: #0fc938;
  color: #fff;
}
.timeline-icon {
  position: absolute;
  left: 15px;
  top: 0;
  width: 30px;
  height: 30px;
  background: #e9ecef;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #6c757d;
}
.timeline-content {
  padding: 5px 10px;
  background: #f8f9fa;
  border-radius: 5px;
}
.timeline-content p {
  margin: 0;
}
.timeline-check {
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 20px;
  color: #0fc938;
}
.vector-images {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.vector-icon {
  font-size: 50px;
  color: #0fc938;
  margin: 0 10px;
}
#card-element {
  padding: 10px;
}
</style>

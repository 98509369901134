<template>
    <div class="image-resizer">
        <div class="drop-zone" @click="openFilePicker" @dragover.prevent @drop.prevent="handleDrop">
            Arrastra una imagen aquí o haz clic para seleccionar
        </div>
        <div v-if="previewSrc" id="preview">
            <img :src="previewSrc" alt="Preview">
        </div>
        <input type="file" ref="fileInput" @change="handleFileChange" style="display: none;">
    </div>
</template>

<script>
export default {
    name: "ImageSize",
    data() {
        return {
            previewSrc: null
        };
    },
    methods: {
        openFilePicker() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith("image/")) {
                this.resizeImage(file);
            }
        },
        handleDrop(event) {
            const file = event.dataTransfer.files[0];
            if (file && file.type.startsWith("image/")) {
                this.resizeImage(file);
            }
        },
        resizeImage(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");

                    canvas.width = 1024;
                    canvas.height = 1024;

                    ctx.drawImage(img, 0, 0, 1024, 1024);
                    this.previewSrc = canvas.toDataURL("image/png");
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }
};
</script>

<style scoped>
.image-resizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.drop-zone {
    width: 300px;
    height: 300px;
    border: 2px dashed #4CAF50;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #4CAF50;
    cursor: pointer;
}

#preview {
    width: 1024px;
    height: 1024px;
    border-radius: 50px;
    overflow: hidden;
}

#preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
}
</style>

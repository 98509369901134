<template>
    <div class="two-house-area pt-100 pb-70" id="info">
        <div class="container">
            <!-- Selector de idioma -->
            <Language :styleType="'language-selector'" />

            <div class="row">
                <div class="col-sm-6 col-lg-4" v-for="zona in zonas" :key="zona.id">
                    <div class="house-item">
                        <div class="house-icon">
                            <i :class="zona.icono"></i>
                        </div>
                        <h3>{{ t(`services.${zona.id}.title`) }}</h3>
                        <p>
                            <b>{{ t(`services.${zona.id}.shortDesc`) }}:</b><br>
                            {{ t(`services.${zona.id}.list1`) }}
                            <br><br>
                            <b>{{ t(`services.${zona.id}.list2`) }}:</b><br>
                            {{ t(`services.${zona.id}.list3`) }}
                        </p>
                        <router-link :to="{ path: '/mobile/request-trip', query: { zonaId: zona.id } }" class="request-now-button">
                            {{ t('services.requestNow') }}
                            <i class="bx bx-right-arrow-alt"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Language from '@/components/MainPage/Language.vue';
import { t } from '@/i18n.js'; // Asegúrate de que esta ruta es correcta

export default {
    name: 'Services',
    components: {
        Language,
    },
    setup() {
        return {
            t,
        };
    },
    data() {
        return {
            zonas: [
                {
                    id: 'premiumService',
                    icono: 'bx bx-car',
                },
                {
                    id: 'easyBooking',
                    icono: 'bx bx-user-voice',
                },
                {
                    id: 'explore',
                    icono: 'bx bx-map',
                },
            ],
        };
    },
};
</script>

<style scoped>
.request-now-button {
    padding: 10px 20px;
    background-color: #0FC938;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    display: inline-block;
    margin-top: 15px;
    cursor: pointer;
}

.request-now-button:hover {
    background-color: #0BAF30;
}

/* Icono en cada zona */
.house-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #3CB371;
}

.house-icon i {
    font-size: 1.8rem;
    color: white;
}

/* Ajustes para evitar que el texto se corte */
.house-item h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
}

.house-item p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.5;
    text-align: justify;
}

/* Diseño responsivo */
@media (max-width: 768px) {
    .request-now-button {
        font-size: 0.9rem;
        padding: 8px 15px;
    }
    .house-icon {
        height: 50px;
        width: 50px;
    }
    .house-icon i {
        font-size: 1.5rem;
    }
    .house-item h3 {
        font-size: 1.2rem;
    }
    .house-item p {
        font-size: 0.9rem;
    }
}
</style>

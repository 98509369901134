<template>
    <div :class="styleType">
        <button @click="changeLanguage('es')" :class="{ active: i18n.language === 'es' }">
            <span class="fi fi-es"></span>
            <template v-if="styleType === 'language-selector'"> Español</template>
            <template v-else> ES</template>
        </button>
        <button @click="changeLanguage('en')" :class="{ active: i18n.language === 'en' }">
            <span class="fi fi-gb"></span>
            <template v-if="styleType === 'language-selector'"> English</template>
            <template v-else> EN</template>
        </button>
        <button @click="changeLanguage('nl')" :class="{ active: i18n.language === 'nl' }">
            <span class="fi fi-nl"></span>
            <template v-if="styleType === 'language-selector'"> Nederlands</template>
            <template v-else> NL</template>
        </button>
    </div>
</template>

<script>
import { i18n } from '@/i18n';

export default {
    name: 'Language',
    props: {
        styleType: {
            type: String,
            default: 'language-selector', // Puede ser 'language-selector' o 'language-switch'
        },
    },
    setup() {
        const changeLanguage = (lang) => {
            i18n.setLanguage(lang);
        };

        return {
            i18n,
            changeLanguage,
        };
    },
};
</script>

<style scoped>
.language-selector {
    display: flex;
    gap: 8px;
}

.language-selector button {
    background: #444;
    border: none;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.language-selector button.active {
    background: #007bff;
}

.language-selector .fi {
    margin-right: 5px;
}

.language-switch {
    display: flex;
    gap: 5px;
}

.language-switch button {
    background: none;
    border: none;
    color: inherit;
    font-size: 14px;
    padding: 3px 8px;
    cursor: pointer;
}

.language-switch button.active {
    font-weight: bold;
    text-decoration: underline;
}

.language-switch .fi {
    margin-right: 3px;
}
</style>

import { createWebHistory, createRouter } from "vue-router";

import MainPage from "../components/Pages/MainPage.vue";
import ImageSize from "../components/MainPage/tools/ImageSize.vue";
import Travels from "../components/MainPage/Travels.vue";
import Taxis from "../components/MainPage/Taxis.vue";
import Legal from "../components/MainPage/Legal.vue";
import RequestTravel from "../components/MainPage/RequestTravel.vue";
import FunFact from "../components/MainPage/mobile/FunFact.vue";
import RequestTrip from "../components/MainPage/mobile/RequestTrip.vue";
import Pagos from "../components/MainPage/pagos.vue";
import Drivers from "../components/MainPage/Drivers.vue"; // Importa el componente Drivers

const routes = [
    { path: "/", name: "Main", component: MainPage },
    { path: "/image-resizer", name: "ImageSize", component: ImageSize },
    { path: "/travels", name: "Travels", component: Travels },
    { path: "/taxis", name: "Taxis", component: Taxis },
    { path: "/legal", name: "Legal", component: Legal },
    { path: "/request-travel", name: "RequestTravel", component: RequestTravel },
    { path: "/mobile", name: "Mobile", component: FunFact },
    { path: "/mobile/request-trip", name: "RequestTrip", component: RequestTrip },
    { path: "/pagos", name: "Pagos", component: Pagos },
    { path: "/drivers", name: "Drivers", component: Drivers }, // Nueva ruta para Drivers
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, behavior: "smooth" };
    },
});

export default router;

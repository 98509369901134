import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Vue3Autocounter from "vue3-autocounter";
import BootstrapVue3 from "bootstrap-vue-3";
import 'flag-icons/css/flag-icons.min.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@mdi/font/css/materialdesignicons.css';
import 'boxicons/css/boxicons.min.css';
import "./assets/custom.scss";

// Import VueMaplibreGL
import VueMaplibre from 'vue-maplibre-gl';

// Import Vuetify
//import vuetify from './plugins/vuetify';

const app = createApp(App)
  .component("vue3-autocounter", Vue3Autocounter);

app.use(BootstrapVue3)
  .use(router)
  .use(VueMaplibre) // Register VueMaplibreGL
  //.use(vuetify) // Register Vuetify
  .mount("#app");


<template>
    <div id="mi_mapa" style="height: 100%; width: 100%;"></div>
  </template>
  
  <script>
  import L from 'leaflet';
  import 'leaflet/dist/leaflet.css';
  import carIconGreen from '@/assets/img/green/car_icon.png'; // Icono verde para conductores reales
  import carIconRed from '@/assets/img/green/car_icon_red.png'; // Icono rojo para vehículos ficticios
  import carIconTaxi from '@/assets/img/green/taxi.png'; // Icono para taxis
  
  import axios from 'axios';
  
  export default {
    name: 'MapDrivers',
    data() {
      return {
        map: null,
        carMarkerIconGreen: L.icon({
          iconUrl: carIconGreen, // Icono verde
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
        carMarkerIconRed: L.icon({
          iconUrl: carIconRed, // Icono rojo
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
        carMarkerIconTaxi: L.icon({
          iconUrl: carIconTaxi, // Icono para taxis
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        }),
        mapboxAccessToken: 'pk.eyJ1Ijoic2FtaWdvcmFiIiwiYSI6ImNtMnNnbGhzdTFjZzEya3NibHd1ZzB0ZXQifQ.g7lY1cJ9CUqUR4uCC_fvBA', // Reemplaza con tu token de acceso
        longitudePickup: -4.8851, // Coordenadas de Marbella
        latitudePickup: 36.5101,
        longitudeDestination: -4.4217, // Coordenadas de Málaga
        latitudeDestination: 36.7213,
        drivers: [],
        fictitiousVehicles: [],
        minimumDistanceMeters: 400, // Distancia mínima entre vehículos ficticios
        maxAttemptsPerVehicle: 10, // Máximo intentos para colocar un vehículo ficticio
      };
    },
    mounted() {
      this.initMap();
      this.getRouteGeometry();
    },
    methods: {
      initMap() {
        this.map = L.map('mi_mapa').setView([36.6156, -4.6531], 10);
  
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; OpenStreetMap contributors',
        }).addTo(this.map);
      },
      async getRouteGeometry() {
        const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${this.longitudePickup},${this.latitudePickup};${this.longitudeDestination},${this.latitudeDestination}`;
        try {
          const response = await axios.get(url, {
            params: {
              alternatives: false,
              geometries: 'geojson',
              steps: false,
              access_token: this.mapboxAccessToken,
            },
          });
  
          if (response.data && response.data.routes && response.data.routes.length > 0) {
            const route = response.data.routes[0];
            const geometry = route.geometry;
  
            // Cargamos los conductores y los ubicamos a lo largo de la ruta
            this.loadDrivers(geometry);
          } else {
            console.error('Mapbox: No se pudo calcular la ruta.');
          }
        } catch (error) {
          console.error('Error al obtener la ruta de Mapbox:', error);
        }
      },
      async loadDrivers(geometry) {
        try {
          const response = await fetch('https://backend.greentransfer.online/api/drivers/');
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
  
          console.log(`Conductores obtenidos de la API: ${data.length}`);
  
          const coordinates = geometry.coordinates;
  
          if (coordinates.length === 0) {
            console.error('La ruta no tiene coordenadas.');
            return;
          }
  
          // Distribuir los conductores de manera uniforme a lo largo de la ruta
          const drivers = data.map((driver, index) => {
            const positionIndex = Math.floor((index / data.length) * coordinates.length);
            let [longitude, latitude] = coordinates[positionIndex % coordinates.length];
  
            // Desplazamiento aleatorio pequeño para evitar superposiciones
            const offset = 0.0005; // Ajusta este valor según sea necesario
            longitude += (Math.random() - 0.5) * offset;
            latitude += (Math.random() - 0.5) * offset;
  
            return {
              ...driver,
              latitude,
              longitude,
            };
          });
  
          this.drivers = drivers;
  
          // Añadir marcadores para conductores reales con el icono correspondiente
          drivers.forEach((driver) => {
            // Selecciona el icono basado en el vehículo
            let icon;
            if (driver.vehicle === 'Taxi Generic') {
              icon = this.carMarkerIconTaxi;
            } else {
              icon = this.carMarkerIconGreen;
            }
  
            const marker = L.marker([driver.latitude, driver.longitude], { icon })
              .addTo(this.map)
              .bindPopup(`
                <strong>${driver.full_name}</strong><br>
                Teléfono: ${driver.phone}<br>
                Email: ${driver.email}<br>
                Nivel: ${driver.level}<br>
                Precio por km: €${driver.price_per_km}<br>
                Comisión: ${driver.commission}%<br>
                Vehículo: ${driver.vehicle || 'N/A'}<br>
                Zonas de trabajo: ${driver.work_zones || 'N/A'}
              `);
          });
  
          // Generar y añadir vehículos ficticios (icono rojo sin popup)
          const numberOfFictitiousVehicles = drivers.length * 5;
          console.log(`Generando ${numberOfFictitiousVehicles} vehículos ficticios.`);
  
          const fictitiousVehicles = [];
  
          for (let i = 0; i < numberOfFictitiousVehicles; i++) {
            let attempts = 0;
            let validPositionFound = false;
            let [longitude, latitude] = [0, 0];
  
            while (attempts < this.maxAttemptsPerVehicle && !validPositionFound) {
              const randomIndex = Math.floor(Math.random() * coordinates.length);
              [longitude, latitude] = coordinates[randomIndex];
  
              // Desplazamiento aleatorio pequeño para evitar superposiciones
              const offset = 0.0005; // Ajusta este valor según sea necesario
              longitude += (Math.random() - 0.5) * offset;
              latitude += (Math.random() - 0.5) * offset;
  
              const newLatLng = L.latLng(latitude, longitude);
  
              // Verificar la distancia con vehículos ficticios ya colocados
              const isFarEnough = fictitiousVehicles.every(vehicle => {
                const existingLatLng = L.latLng(vehicle.latitude, vehicle.longitude);
                return newLatLng.distanceTo(existingLatLng) >= this.minimumDistanceMeters;
              });
  
              if (isFarEnough) {
                validPositionFound = true;
              } else {
                attempts++;
              }
            }
  
            if (validPositionFound) {
              fictitiousVehicles.push({ latitude, longitude });
            } else {
              console.warn(`No se pudo colocar el vehículo ficticio ${i + 1} después de ${this.maxAttemptsPerVehicle} intentos.`);
            }
          }
  
          this.fictitiousVehicles = fictitiousVehicles;
  
          fictitiousVehicles.forEach((vehicle, index) => {
            L.marker([vehicle.latitude, vehicle.longitude], { icon: this.carMarkerIconRed })
              .addTo(this.map);
          });
  
          console.log('Marcadores de conductores y vehículos ficticios añadidos al mapa.');
          console.log(`Vehículos ficticios colocados: ${fictitiousVehicles.length}`);
  
          // Ajustar la vista del mapa para incluir todos los marcadores
          this.fitMapToMarkers();
        } catch (error) {
          console.error('Error al cargar los datos de los conductores:', error);
        }
      },
      fitMapToMarkers() {
        const allMarkers = [
          ...this.drivers.map(driver => [driver.latitude, driver.longitude]),
          ...this.fictitiousVehicles.map(vehicle => [vehicle.latitude, vehicle.longitude]),
        ];
  
        if (allMarkers.length > 0) {
          const bounds = L.latLngBounds(allMarkers);
          this.map.fitBounds(bounds, { padding: [50, 50] });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  #mi_mapa {
    height: 100%;
    width: 100%;
  }
  </style>
  
<template>
    <footer class="one-footer-area pt-100">
        <div class="container">
            <div class="row">
                <!-- Sección de logo y descripción -->
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-logo">
                            <a href="#">
                                <img src="../../assets/img/green/logoban.png" alt="Logo" style="width: 200px; height: auto;">
                            </a>
                            <p>greentransfer.com <br>Málaga y alrededores</p>
                        </div>
                    </div>
                </div>
                
                <!-- Dirección y selector de idioma -->
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-address">
                            <h3>{{ t('footer.address') }}</h3>
                            <ul>
                                <li>
                                    <i class='bx bx-current-location'></i>
                                    {{ t('footer.location') }}
                                </li>
                                <li>
                                    <i class='bx bx-planet'></i>
                                    {{ t('footer.aiPowered') }}
                                </li>
                            </ul>
                            <!-- Añadiendo el selector de idioma -->
                            <Language />
                        </div>
                    </div>
                </div>
                
                <!-- Sección de métodos de pago -->
                <div class="col-sm-6 col-lg-3">
                    <div class="footer-item">
                        <div class="footer-quick">
                            <h3>{{ t('footer.aboutUs') }}</h3>
                            <ul>
                                <li>
                                    <a href="/legal">{{ t('footer.token') }}</a>
                                </li>
                                <li>
                                    <a  href="/legal">{{ t('footer.joinUs') }}</a>
                                </li>
                                <li>
                                    <a  href="/legal">{{ t('footer.mobilityPack') }}</a>
                                </li>
                                <li>
                                    <a  href="/legal">{{ t('footer.services') }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Área de copyright y métodos de pago -->
            <div class="copyright-area">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="copyright-item">
                            <ul>
                                <li><a href="/mobile/request-trip"><img src="../../assets/img/home-one/visa.jpg" alt="Visa"></a></li>
                                <li><a href="/mobile/request-trip"><img src="../../assets/img/home-one/master-card.jpg" alt="Master Card"></a></li>
                                <li><a href="/mobile/request-trip"><img src="../../assets/img/home-one/paypal.jpg" alt="Paypal"></a></li>
                                <li><a href="/mobile/request-trip"><img src="../../assets/img/home-one/skrill.jpg" alt="Skrill"></a></li>
                                <li><a href="/mobile/request-trip"><img src="../../assets/img/home-one/master-card2.jpg" alt="Master Card 2"></a></li>
                                <li><a href="/mobile/request-trip"><img src="../../assets/img/home-one/visa2.jpg" alt="Visa 2"></a></li>

                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="copyright-item">
                            <p>{{ t('footer.copyright').replace('{year}', currentYear) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { t } from '../../i18n.js';
import Language from './Language.vue';

export default {
    name: 'Footer',
    components: {
        Language,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
    methods: {
        t,
    },
};
</script>

<style scoped>
/* Estilos del footer */
.footer-item {
    margin-bottom: 30px;
}

.footer-logo img {
    max-width: 100%;
}

.footer-address ul,
.footer-quick ul {
    list-style: none;
    padding: 0;
}

.footer-address ul li,
.footer-quick ul li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.footer-address ul li i,
.footer-quick ul li i {
    margin-right: 10px;
    color: #6c757d;
}

.copyright-area {
    padding-top: 20px;
    border-top: 1px solid #e9ecef;
    margin-top: 20px;
}

.copyright-item ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.footer-quick a {
    color: #6c757d;
    text-decoration: none;
}

.footer-quick a:hover {
    color: #2EC82B;
}
</style>


<template>
    <div class="container">
      <table class="drivers-table">
        <thead>
          <tr>
            <th>Zona</th>
            <th>Población</th>
            <th>Teléfono del Conductor</th>
            <th>Chatear por WhatsApp</th>
          </tr>
        </thead>
        <tbody>
          <!-- Iteramos sobre cada conductor en la lista -->
          <tr v-for="(driver, index) in drivers" :key="index">
            <td>{{ driver.zona }}</td>
            <td>{{ driver.poblacion }}</td>
            <td>{{ driver.telefono }}</td>
            <td>
              <a
                :href="getWhatsAppLink(driver.telefono)"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Chatear por WhatsApp"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                  alt="WhatsApp"
                  class="whatsapp-icon"
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        drivers: []
      };
    },
    mounted() {
      fetch('/data/taxi_drivers.json')
        .then(response => response.json())
        .then(data => {
          this.drivers = data;
        })
        .catch(error => {
          console.error('Error al cargar los datos de conductores:', error);
        });
    },
    methods: {
      getWhatsAppLink(phone) {
        const formattedPhone = phone.replace(/\D/g, '');
        return `https://wa.me/${formattedPhone}`;
      }
    }
  };
  </script>
  
  <style>
  .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    overflow-x: auto;
  }
  
  .drivers-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: Arial, sans-serif;
    table-layout: auto;
  }
  
  .drivers-table th,
  .drivers-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left;
    vertical-align: middle;
    word-wrap: break-word;
  }
  
  .drivers-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }
  
  .drivers-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .whatsapp-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.2s;
  }
  
  .whatsapp-icon:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 600px) {
    .drivers-table th,
    .drivers-table td {
      padding: 8px 10px;
      font-size: 12px;
    }
  
    .whatsapp-icon {
      width: 20px;
      height: 20px;
    }
  }
  </style>
  
<template>
    <div class="one-counter-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='12' 
                                :duration='6' 
                            />
                        </h3>
                        <p>AÑOS DE EXPERIENCIA</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='120' 
                                :duration='7'
                            />
                        </h3>
                        <p>Miles de CLIENTES SATISFECHOS</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='180' 
                                :duration='8'
                            />
                        </h3>
                        <p> mas de miles TRAYECTOS REALIZADOS</p>
                    </div>
                </div>
                <div class="col-sm-6 col-lg-3">
                    <div class="counter-item">
                        <h3>
                            <vue3-autocounter 
                                ref='counter' 
                                :startAmount='0' 
                                :endAmount='  65' 
                                :duration='5'
                            />
                        </h3>
                        <p>POBLACIONES DONDE TRABAJAMOS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Counter'
}
</script>
<template>
    <div class="legal-container">
      <h1>Términos y Condiciones Generales</h1>
      <p>
        Bienvenido a nuestra plataforma de servicios de Vehículos de Turismo con Conductor (VTC). Al acceder y utilizar nuestros servicios, aceptas estar sujeto a los siguientes términos y condiciones. Si no estás de acuerdo con estos términos, te recomendamos no utilizar nuestra plataforma.
      </p>
  
      <!-- Botón para descargar el PDF -->
      <button @click="downloadPDF" class="pdf-button">Descargar en PDF</button>
  
      <h2>Política de Privacidad</h2>
      <p>
        Tu privacidad es de suma importancia para nosotros. Nos comprometemos a proteger tus datos personales y a utilizarlos únicamente para mejorar tu experiencia en nuestra plataforma. Recopilamos información como tu nombre, correo electrónico, número de teléfono y detalles de pago para procesar tus solicitudes y ofrecerte un servicio personalizado.
      </p>
      <p>
        Tus datos serán tratados conforme a lo dispuesto en el Reglamento General de Protección de Datos (RGPD) y demás normativa aplicable. No compartiremos tu información personal con terceros sin tu consentimiento expreso, salvo cuando sea necesario para completar una transacción o cuando la ley lo requiera.
      </p>
      <p>
        Puedes consultar nuestra <a href="/politica-de-privacidad">Política de Privacidad completa</a> para obtener más detalles sobre cómo manejamos tu información, tus derechos en relación con tus datos personales y cómo ejercerlos.
      </p>
  
      <h2>Política de Devoluciones y Cancelaciones</h2>
      <p>
        Entendemos que pueden surgir imprevistos que te obliguen a cambiar tus planes. Por ello, ofrecemos una política de cancelación flexible diseñada para adaptarse a tus necesidades:
      </p>
      <ul>
        <li>
          <strong>Cancelaciones realizadas con más de 24 horas de antelación:</strong> Reembolso completo del importe pagado.
        </li>
        <li>
          <strong>Cancelaciones realizadas entre 24 y 2 horas antes del viaje:</strong> Reembolso del 50% del importe pagado.
        </li>
        <li>
          <strong>Cancelaciones realizadas con menos de 2 horas de antelación:</strong> No se ofrecerá reembolso debido a los costes operativos incurridos.
        </li>
      </ul>
      <p>
        Para viajes al aeropuerto y otros destinos clave, ofrecemos un <strong>Seguro de Cancelación</strong> opcional que, por una tarifa adicional, te permite cancelar sin penalizaciones hasta 1 hora antes del viaje. Este seguro está diseñado para brindarte mayor flexibilidad y tranquilidad.
      </p>
      <p>
        Para solicitar una cancelación o reembolso, por favor ponte en contacto con nuestro equipo de atención al cliente a través de nuestro <strong>Teléfono Virtual</strong> o mediante el formulario de contacto en nuestra plataforma.
      </p>
  
      <h2>Tokenización de Licencias VTC</h2>
      <p>
        Hemos implementado un innovador sistema de <strong>Tokenización de Licencias VTC</strong>. Esto significa que cada licencia de nuestros conductores está representada digitalmente mediante tokens seguros en nuestra plataforma. La tokenización facilita la transferencia, seguimiento y verificación de las licencias, garantizando transparencia y seguridad tanto para conductores como para usuarios.
      </p>
      <p>
        Este sistema permite verificar la autenticidad y validez de las licencias de manera rápida y eficiente, reduciendo el riesgo de fraude y asegurando que todos nuestros conductores cumplen con los requisitos legales y de calidad establecidos.
      </p>
      <p>
        Además, la tokenización ayuda a mantener actualizada la información de los conductores y a gestionar de forma más eficaz la oferta de servicios disponibles en nuestra plataforma.
      </p>
  
      <h2>Sistema de Oferta y Demanda</h2>
      <p>
        Nuestro <strong>Sistema de Cálculo de Pagos</strong> se basa en un modelo dinámico que considera múltiples factores, incluyendo la distancia del trayecto, el tiempo estimado, la demanda actual de vehículos en determinadas zonas y la disponibilidad de conductores.
      </p>
      <p>
        Durante períodos de alta demanda, aplicamos un <strong>Ajuste de Puja para Conductores VTC</strong>. Esto permite a los conductores ajustar sus tarifas dentro de unos límites establecidos, incentivando así una mayor disponibilidad de vehículos en zonas y momentos de alta demanda. Por su parte, los usuarios tienen la opción de ajustar su puja mediante un <strong>Control Deslizante</strong> en la aplicación, lo que puede priorizar su solicitud y reducir el tiempo de espera.
      </p>
      <p>
        Este sistema equilibra la oferta y la demanda, asegurando que los usuarios obtengan el mejor servicio posible y que los conductores sean compensados de manera justa por su trabajo, especialmente en momentos de mayor carga laboral.
      </p>
  
      <h2>Agentes Virtuales y Protección de Datos</h2>
      <p>
        En cumplimiento con el Reglamento General de Protección de Datos (RGPD) y para proteger la privacidad de nuestros conductores, algunos de los conductores que se muestran en nuestra plataforma pueden ser representados como <strong>Agentes Virtuales</strong>. Esto significa que, en ciertos casos, los perfiles mostrados son representaciones digitales que garantizan la confidencialidad de la identidad de los conductores.
      </p>
      <p>
        Esta medida asegura que se cumplan todas las normativas de protección de datos, al tiempo que proporcionamos a los usuarios información suficiente para que puedan tomar decisiones informadas al seleccionar un conductor. Una vez confirmada la reserva, te proporcionaremos los detalles completos del conductor asignado, incluyendo su nombre y datos de contacto, para facilitar la comunicación y coordinación del servicio.
      </p>
      <p>
        Nos comprometemos a manejar tus datos personales y los de nuestros conductores con el máximo respeto y confidencialidad, implementando medidas de seguridad avanzadas para prevenir accesos no autorizados y garantizar la integridad de la información.
      </p>
  
      <h2>Características Avanzadas</h2>
      <ul>
        <li>
          <strong>Pack de Chofer por Demanda para Excursiones Guiadas:</strong> Ofrecemos servicios de chofer por demanda para excursiones guiadas personalizadas. Nuestros conductores profesionales no solo te llevarán a tu destino, sino que también pueden actuar como guías turísticos, compartiendo conocimientos locales y recomendaciones exclusivas para que tu experiencia sea inolvidable.
        </li>
        <li>
          <strong>Seguro de Cancelación para Viajes al Aeropuerto:</strong> Entendemos que los planes de viaje pueden cambiar inesperadamente. Con nuestro seguro de cancelación, puedes proteger tu reserva y obtener la flexibilidad de cancelar sin penalizaciones hasta 1 hora antes de la hora programada de recogida.
        </li>
        <li>
          <strong>Control Deslizante para Ajuste de Puja del Cliente:</strong> Durante períodos de alta demanda, puedes utilizar nuestro control deslizante para ajustar tu puja y priorizar tu solicitud. Esto te permite obtener un vehículo más rápido al ofrecer una tarifa ligeramente superior, lo que puede ser especialmente útil cuando tienes prisa o necesitas garantizar un servicio en momentos pico.
        </li>
        <li>
          <strong>Sistema de Viajes Compartidos:</strong> Promovemos la sostenibilidad y eficiencia con nuestro sistema de viajes compartidos. Puedes optar por compartir tu trayecto con otros usuarios que tengan rutas similares, reduciendo el costo del viaje y contribuyendo a disminuir la huella de carbono.
        </li>
        <li>
          <strong>Sistema de Métricas con Inteligencia Artificial:</strong> Utilizamos tecnología de inteligencia artificial para analizar patrones y métricas clave. Esto nos permite mejorar continuamente la eficiencia, optimizar las rutas, predecir la demanda y ofrecer recomendaciones personalizadas, elevando la calidad de nuestros servicios.
        </li>
        <li>
          <strong>Verificación Antes de la Recogida:</strong> Para garantizar tu seguridad y satisfacción, realizamos una verificación previa antes de la recogida. Confirmamos los detalles del conductor y del vehículo, asegurándonos de que cumplan con todos los estándares de calidad y seguridad. También te proporcionamos información detallada para que puedas reconocer fácilmente a tu conductor.
        </li>
        <li>
          <strong>Registro de Solicitudes para Conductores:</strong> Los conductores cuentan con un sistema optimizado para gestionar y aceptar solicitudes de manera eficiente. Pueden ver las solicitudes en tiempo real, ajustar su disponibilidad y comunicarse directamente con los usuarios en caso de ser necesario.
        </li>
        <li>
          <strong>Teléfono Virtual en la Cabecera:</strong> Para mejorar la comunicación y tiempos de respuesta, hemos implementado un número de teléfono virtual en la cabecera de nuestra plataforma. Este número te conecta automáticamente con el operador disponible, asegurando que siempre puedas obtener asistencia cuando la necesites.
        </li>
        <li>
          <strong>Botones para Contactar con el Conductor:</strong> Una vez que se te ha asignado un conductor, puedes contactarlo directamente desde la aplicación mediante botones dedicados. Esto facilita la coordinación, permite resolver dudas rápidamente y mejora la experiencia general del usuario.
        </li>
        <li>
          <strong>Opción de Pago en la App o a Través de Enlace:</strong> Te ofrecemos flexibilidad en los métodos de pago. Puedes optar por pagar directamente en la aplicación con tarjeta de crédito o débito, o bien, recibir un enlace seguro para realizar el pago a través de tu método preferido. Todos nuestros sistemas de pago cumplen con los más altos estándares de seguridad.
        </li>
      </ul>
  
      <h2>Información Importante</h2>
      <p>
        Al seleccionar un conductor, te proporcionamos toda la información disponible para que tomes una decisión informada. Sin embargo, es importante tener en cuenta que pueden surgir imprevistos como condiciones de tráfico, obras viales o condiciones climáticas adversas que podrían afectar el tiempo estimado de llegada y el trayecto. Te recomendamos considerar estos factores al planificar tu viaje.
      </p>
      <p>
        Nos esforzamos por minimizar cualquier inconveniente y mantenerte informado en todo momento. Nuestra plataforma cuenta con actualizaciones en tiempo real y notificaciones que te alertarán sobre cualquier cambio relevante en tu servicio.
      </p>
  
      <h2>Aviso Legal</h2>
      <p>
        Todos los derechos reservados. Queda prohibida la reproducción total o parcial de este sitio web y de sus contenidos sin el permiso expreso y por escrito de la empresa. No nos responsabilizamos por el mal uso de la plataforma ni por daños o perjuicios derivados del mismo.
      </p>
      <p>
        Los servicios ofrecidos están sujetos a disponibilidad y pueden ser modificados sin previo aviso. Nos reservamos el derecho de actualizar o cambiar estos términos y condiciones en cualquier momento. Es responsabilidad del usuario revisar periódicamente esta sección para estar al tanto de cualquier modificación.
      </p>
      <p>
        En caso de discrepancias o conflictos, se aplicarán las leyes y jurisdicciones correspondientes al país o región donde opera la empresa.
      </p>
  
      <h2>Información de la Empresa</h2>
      <p>
        Esta plataforma es operada por <strong>CHAIN DEVELOP S.L.</strong>, con CIF <strong>B70592282</strong>, bajo el nombre comercial <strong>Green Transfer</strong>. Nuestra sede se encuentra en Calle Cobre número 2, 15100 Carballo, A Coruña.
      </p>
      <p>
        Para cualquier consulta o comunicación oficial, puedes dirigirte a nuestras oficinas o utilizar el teléfono virtual que encontrarás en la cabecera de nuestra plataforma.
      </p>
  
      <h2>Contacto</h2>
      <p>
        Si tienes alguna duda, comentario o necesitas más información, no dudes en contactarnos. Puedes comunicarte con nosotros a través de nuestro <strong>Teléfono Virtual</strong>, disponible las 24 horas del día, o mediante el formulario de contacto en nuestra plataforma. Nuestro equipo de atención al cliente estará encantado de asistirte y resolver cualquier inquietud que puedas tener.
      </p>
    </div>
  </template>
  
  <script>
  import jsPDF from 'jspdf';
  
  export default {
    name: 'Legal',
    methods: {
      downloadPDF() {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text("Términos y Condiciones Generales", 10, 10);
  
        // Recoger el contenido de la política y añadir al PDF
        const content = document.querySelector('.legal-container').innerText;
        doc.text(content, 10, 20);
  
        // Guardar el PDF
        doc.save('Términos_y_Condiciones.pdf');
      }
    }
  };
  </script>
  
  <style scoped>
  .legal-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .legal-container h1,
  .legal-container h2 {
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .legal-container p {
    color: #34495e;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .legal-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .legal-container li {
    margin-bottom: 15px;
  }
  
  .legal-container strong {
    color: #2980b9;
  }
  
  .pdf-button {
    display: inline-block;
    padding: 10px 20px;
    margin: 20px 0;
    background-color: #2980b9;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .pdf-button:hover {
    background-color: #1f6392;
  }
  </style>
  
<template>
    <div>
        <!--<NavbarOne />-->
        <MainBanner />
        <Counter class="two-counter-area" />
        <WeHaveOffer />
        <Faq /> 
        <Services />
        <FunFact />
        <Footer/>
    </div>
</template>

<script>
import '@mdi/font/css/materialdesignicons.css'
import 'boxicons/css/boxicons.min.css'

// Importaciones de otros componentes
//import NavbarOne from '../MainPage/NavbarOne'
import MainBanner from '../MainPage/MainBanner'
import Counter from '../MainPage/Counter'
import WeHaveOffer from '../MainPage/WeHaveOffer'
import Services from '../MainPage/Services'
import FunFact from '../MainPage/FunFact'
import Footer from '../MainPage/Footer'
import Faq from './Faq' // Importación del archivo Faq.vue

export default {
    name: 'MainPage',
    components: {
        //NavbarOne,
        MainBanner,
        Counter, 
        WeHaveOffer,
        Faq, // Declaración del componente Faq
        Services,
        FunFact,
        Footer,
    }
}
</script>

<template>
    <div class="one-help-area two-help-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-7 p-0">
                    <div class="help-item">
                        <!-- Selector de idioma usando el componente Language.vue -->
                        <Language :styleType="'language-switch'" />

                        <div class="one-section-title">
                            <span class="sub-title">{{ i18n.translations[i18n.language].subtitle }}</span>
                            <h2>{{ i18n.translations[i18n.language].title }}</h2>
                            <p>{{ i18n.translations[i18n.language].description }}</p>
                        </div>
                        <ul>
                            <li>
                                <div class="help-icon">
                                    <i class='bx bx-car'></i>
                                </div>
                                <div class="help-inner">
                                    <h3>{{ i18n.translations[i18n.language].airportTransfer.title }}</h3>
                                    <p>{{ i18n.translations[i18n.language].airportTransfer.description }}</p>
                                </div>
                            </li>
                            <li>
                                <div class="help-icon">
                                    <i class='bx bx-star'></i>
                                </div>
                                <div class="help-inner">
                                    <h3>{{ i18n.translations[i18n.language].premiumService.title }}</h3>
                                    <p>{{ i18n.translations[i18n.language].premiumService.description }}</p>
                                </div>
                            </li>
                        </ul>
                        <div class="advisor-link">
                            <!-- Botón Solicitar Ahora para abrir la ruta -->
                            <router-link :to="{ path: '/mobile/request-trip' }" class="request-now-button">
                                {{ i18n.translations[i18n.language].requestNow }}
                            </router-link>
                            <i class="bx bx-right-arrow-alt"></i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 p-0">
                    <div class="help-img-two">
                        <img src="../../assets/img/home-two/fun.jpg" alt="Green Transfer">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { i18n } from '@/i18n';
import Language from './Language.vue'; // Ruta relativa, ya que ambos archivos están en MainPage

export default {
    name: 'FunFact',
    components: {
        Language,
    },
    setup() {
        return {
            i18n,
        };
    },
};
</script>

<style scoped>
.one-help-area {
    background-color: #333;
    color: #fff;
}

.request-now-button {
    font-size: 1rem;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.request-now-button:hover {
    background-color: #0056b3;
}
</style>
